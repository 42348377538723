import React, { useEffect } from 'react'
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxSwitch } from '../../Action/FxSwitch';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { FxButton } from '../../Action/FxButton';
import { ageLimit } from '../../Utils/CommonBaseClass';
import { convertToPhoneDisplayFormat, setPhoneValidationOptions, setZipValidationOptions, renderCityNameError, setCityNameValidation } from '../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { AUTH_STRINGS } from '../../../constants/strings';
import usa_state from '../../Utils/usa_states.json';
import { useMediaQuery } from 'react-responsive';
import { getKey } from '../../../libs/utils/storageManager';
import { FxTextEditSSN } from '../../Input/FxSSN/FxTextEditSSN';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import _ from "lodash";

const httpClient = HttpClient.getClient();


Logger.debug("CreateUser.tsx", "create user initializing");
/**
 * Component: CreateUser
 * Usage: to create a user
 */
interface InputErrType {
    type: string;
    message: string;
}
export const CreateAuthorisedUser: React.FC<any> = React.memo((props) => {
        const isMobileScreen = useMediaQuery({ query: '(max-width: 50rem)' })
        let context: any;
        const role_src = {
            url: "role/list",
            method: "POST",
            data: {
                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters": 
                        [{
                            "key": "name",
                            "operator": "like",
                            "values": [
                                ""
                            ]
                        }]
                }
            }
        };

        let userId: any;
        ({ context, props } = RegisterComponent(props));
        const { register, formState: { errors }, handleSubmit, setValue, setError, control, clearErrors } = useForm();
        const [isloading, setIsloading] = React.useState(false);
        const [isDataLoaded, setIsDataLoaded] = React.useState(false);
        const [submitButton, setSubmitButton] = React.useState('ADD AUTHORIZED USER');
        const [isEdit, setIsEdit] = React.useState(props.source ? true : false);
        const history = useHistory();
        const dispatch = useDispatch();
        const [primaryAddress, setPrimaryAddress] = React.useState<any>();
        const [roleList, setRoleList] = React.useState<any>([]);
        const [portalAccessSwitch, setPortalAccessSwitch] = React.useState(true);
        const [rolesData, setRolesData] = React.useState<any>([]);

        /**
         * Method to check if data available in context for edit
         * */

        if (context?.data?.body && !isDataLoaded) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Authorized User', backButton: '/authorizedusers/view/'+context?.data?.body?.id } } });
            const address = context.data.body.mailingAddress?.find((address: { isPrimary: boolean }) => address.isPrimary);
            setPrimaryAddress(address);

            setPortalAccessSwitch(context?.data?.body?.portalAccess?.grantAccess);
            setIsDataLoaded(true); // set as true if edit data available
            setIsEdit(true);
            setSubmitButton('Save');
            if(context?.data?.body?.portalAccess?.role){
                if(context?.data?.body?.portalAccess?.role.length > 0){
                    const roles =  customerDataTransformation(context?.data?.body?.portalAccess?.role)
                    setRoleList(roles);
                } 
            }
        }
        /**
         * Mehtod called before component loading
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if(!props?.source){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Authorized User', backButton: '/authorizedusers' } } });
            }
            getRoles(role_src.data, true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        /**
         * Method to handle on submit request
         * */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            const formattedPhoneNumber = data.mobilePhone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');


            req = {
                "firstName": data.firstName,
                //"middleName": data.middleName,
                "lastName": data.lastName,
                "dob": data.dob?data.dob: null,
                "email": data.email,
                "isUSCitizen": true,
                "mobilePhone": formattedPhoneNumber,
                "countryCode": data['mobilePhone-select'],
            };
            if(data.username){
                req['username'] = data.username;
            }
            if(!_.isEmpty(data.addressLine1) || !_.isEmpty(data.addressLine1) || !_.isEmpty(data.city) || !_.isUndefined(data.state)|| !_.isEmpty(data.zip)){
                req.mailingAddress = [
                    {
                        "id": context?.data?.body?.mailingAddress?.[0]?.id? context.data.body.mailingAddress[0].id : undefined,
                        "addressLine1": data.addressLine1 !== ""? data.addressLine1 : undefined ,
                        "addressLine2": data.addressLine2 !== ""? data.addressLine2 : undefined ,
                        "city": data.city !== ""? data.city : undefined ,
                        "state": data.state !== ""? data.state : undefined ,
                        "zip": data.zip !== ""? data.zip : undefined ,
                        "isPrimary": true
                    }
                ]
            }
            req["portalAccess"] = {
                grantAccess: portalAccessSwitch,
                role: portalAccessSwitch ? data.role.map((role: any) => String(role.value)) : undefined
            }

            if(data?.ssn === ''){
                delete req["ssn"]
            }
            else{
                req["ssn"] = data?.ssn;
            }

            if (context?.data?.body) {
                status = await updateUser(req);
            } else {
                status = await createUser(req);
            }
            if (status?.headers?.url) {
                userId = (status.headers.url).split('/').pop();
            }
            status = processAPIResponse(status)
            setIsloading(false);
            if (status.status) {
                // api success
                if (context?.data?.body) {
                    history.push('/authorizedusers/view/' + context.data.body.id)
                } else {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Authorized User Created Successfully!',
                    });
                    history.push('/thanks/manage/authorized-user/' + userId);
                }

            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
         * handle cancel
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/authorizedusers/view/' + context.data.body.id)
            }
            else {
                history.push('/authorizedusers')
            }
        };

        /**
         * Method to call api  for create user
         * */
        async function createUser(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('authorizedUser',false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateUser.tsx", "error", err);
                return err;
            }
        }
        /**
         * Method to call api  for update user
         * */
        async function updateUser(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('authorizedUser/id/' + context?.data?.body?.id,false), paylaoddata).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateUser.tsx", "error", err);
                return false;
            }
        }
        /**
         * ssn validation
         */
        const setSsnValidationOptions = {
            required: false,
            //AUTH_STRINGS.ERRORS.CUSTOMER_SSN_PLEASE_ENTER
            maxLength: 11,
            pattern: {
                value: /^\d{3}-?\d{2}-?\d{4}$/,
                message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
            },
        };

        /**
         * ssn render error
         * @param err
         * @returns
         */
        const renderError = (err: InputErrType): string => {
            return err.message;
        };

        /**
         * Method to handle error
         * @param err
         * @returns
         */
        const renderZipError = (err: InputErrType): string => {
            return err.message;
        };

       /**
        * handle chnage for portal access
        * @param event :click event
        */
        const handlePortalAccess = (event: any) => {
            setPortalAccessSwitch(event);
        }

       /**
        * To transform the data
        * @param data: Data received from api
        */
        function customerDataTransformation(data: any) {
            const roles = data && data?.map((role: any) => {
                return { value: role.id, label: `${role.name} ID: ${role.id}` };
            });
            return roles;
        }

       /**
        * To do the api call based on the user search
        * @param searchValue: user entered value
        */
        async function onRoleSearch(searchValue: any) {
            if(searchValue){
            const payloadData =  {
                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters": [
                        {
                            "key": "name",
                            "operator": "like",
                            "values": [
                                searchValue
                            ]
                        }
                    ]
                }
            }
            
             return getRoles(payloadData);
        }
        }
    
    /**
     * Method used to call request roles
     * @param body :form data
     * @returns
     */
    async function getRoles (payloadData: any, isOnload = false) {
        try {
            await httpClient.post(getCustomerUrl(role_src.url, false), payloadData).then(response => {
                if (response.data && response.data.resources && response.data.resources.length > 0) {
                      const transformedData = customerDataTransformation(response.data.resources);
                      isOnload && setRolesData(transformedData);
                      return transformedData;
                }
                return response.data.resources;
           })
       } catch (err) {
           Logger.error("CreateAuthorisedUser.tsx", "error", err);
           return err;
       }
    }


        return (
            <Grid item container xs={12} sm={12} justifyContent={isMobileScreen?'flex-start':'center'} className='fx-form-grid' >
                <Grid item sm={6} xs={12}>
                    <Grid className="fx-card-general fx-theme-passport fx-no-box-shadow">
                        {!isDataLoaded && isEdit && <FxSkeltonList height="40rem" />}
                        {(isDataLoaded || !isEdit) && <form id="create-user-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCard id="create-user-form-card">
                                <FxCardHeader id="create-user-form-card-header" >
                                    {/* title */}
                                </FxCardHeader>
                                <FxCardBody id="create-user-form-card-body" className="fx-info-card" >
                                    <Grid container direction="row" spacing={2} className="fx-form-edit-profile" >
                                        <Grid item xs={12} sm={12}>
                                            <Typography className="filter-popover-label">BASIC INFORMATION</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-user-form-card-first-name-textbox" label="First Name*" name="firstName" variant="outlined" defaultValue={context?.data?.body?.firstName ? context.data.body.firstName : ''} />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("middleName") }} className={errors.middleName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-user-form-card-middle-name-textbox" label="Middle Name" name="middleName" variant="outlined" defaultValue={context?.data?.body?.middleName ? context.data.body.middleName : ''} />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("lastName") }} className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-user-form-card-last-name-textbox" label="Last Name*" name="lastName" variant="outlined" defaultValue={context?.data?.body?.lastName ? context.data.body.lastName : ''} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("email") }} className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: isEdit ? false : true }} id="create-user-form-card-email-textbox" label="Email*" name="email" type="email" variant="outlined" defaultValue={context?.data?.body?.email ? context.data.body.email : ''} isEditable={true} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FxDateEdit register={{ ...register('dob') }}
                                                        className={errors.dob ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                        control={control} rules={{ required: false }} id="dob"
                                                        label="Date of Birth" name="dob" setValue={setValue}
                                                        variant="outlined"
                                                        defaultValue={context?.data?.body?.dob ? context.data.body.dob : ''}
                                                        disableFuture={true} maxDate={ageLimit(18)}
                                                        ageLimitMessage={AUTH_STRINGS.ERRORS.AGE_MORE_THAN_18}
                                                        defaultCalendarMonth={ageLimit(18)} />
                                            <div className={'error-message'}>
                                              {errors.dob && renderError(errors.dob)}
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FxTextEditSSN
                                                register={{ ...register('ssn') }}
                                                className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                error={errors?.ssn}
                                                control={control}
                                                rules={(context?.data?.body?.taxIdentification?.idLast4 || context?.data?.body?.taxIdentification?.id) ? {required: false, disabled: true} :setSsnValidationOptions}
                                                id="signUp-indivudal-form-card-ssn"
                                                label="Social Security Number"
                                                placeholder="XXX-XX-XXXX"
                                                name="ssn"
                                                variant="outlined"
                                                defaultValue={context?.data?.body?.last4ssn ? context.data.body.last4ssn : ''}
                                                setValue={setValue}
                                                setError={setError}
                                                clearErrors={clearErrors}
                                            />
                                            <div className={'error-message'}>
                                                {errors.ssn && renderError(errors.ssn)}
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="mobilePhone" name="mobilePhone" label="Phone Number *" defaultCountryCode={context?.data?.body?.countryCode || getKey('countryCode')} defaultValue={convertToPhoneDisplayFormat(context?.data?.body?.mobilePhone)} isEditable={true} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                            <div className={'error-message'}>
                                                {errors.mobilePhone && renderError(errors.mobilePhone)}
                                            </div>
                                        </Grid>

                                        <Grid item container xs={12} sm={12}>
                                            <fieldset className="fx-add-user-address-container">
                                                <legend>Address Details</legend>
                                                <Grid item container xs={12} sm={12} spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false,maxLength:30 }} id={'payee-address-add-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} inputProps={{maxLength:30}} defaultValue={primaryAddress?.addressLine1 ? primaryAddress.addressLine1 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false ,maxLength:30 }} id={'payee-address-add-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{maxLength:30}} defaultValue={primaryAddress?.addressLine2 ? primaryAddress.addressLine2 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(false)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-city-textbox" name="city" label="City" defaultValue={primaryAddress?.city} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: false }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-address-add-state-textbox" name="state" data={usa_state} label="State" value={primaryAddress?.state}
                                                            setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(false)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-zip-textbox" name="zip" label="Zip" defaultValue={primaryAddress?.zip} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderZipError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                         <Grid item xs={12} sm={12}>
                                            <Grid container alignItems="center" >
                                                <Grid item >
                                                <FxSwitch id='repeat-switch'  value={portalAccessSwitch} onClick={handlePortalAccess}></FxSwitch> <FxLabel value="Share Portal Access"></FxLabel>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                         {portalAccessSwitch && <>
                                            {/* Commented for this sprint and need in next sprint */}
                                            {/* {!isEdit && <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('username') }} control={control} className={errors['username'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'create-authorized-user-username-textbox'} label="Username" name={'username'} />
                                                    </Grid>} */}
                                            <Grid item xs={12}>
                                            <Typography className="filter-popover-label" >ASSIGN ROLES </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} className='fx-authorised-user-card'>
                                            <Grid item className='fx-autosearch-height'>
                                                <FxMaterialMultipleSelect 
                                                 register={{ ...register("role") }}
                                                 id="create-auth-user-role"
                                                 name='role' 
                                                 data={rolesData} 
                                                 control={control} 
                                                 canSearch={true}
                                                 rules={{ required: portalAccessSwitch ? true: false }} 
                                                 dataTransformation={customerDataTransformation}
                                                 onChange={onRoleSearch}
                                                 value={roleList} 
                                                 isEdit={props.isEdit ? true: false}
                                                 selectAllLabel={"All Roles"}
                                                 label='Role *' 
                                                  setValue={setValue} />
                                            </Grid>
                                        </Grid>
                                            </>}
                                        <Grid item xs={12} sm={!isMobileScreen ? 6 : 12}></Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="create-user-form-card-footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                        <FxButton variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="create-user-form-card-cancel-button"
                                            onClick={handleClose}>
                                            Cancel
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id={"create-user-form-card-" + submitButton + "-button"}
                                            type="submit"
                                        >
                                            {isloading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                submitButton
                                            )}
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </FxCard>
                        </form>}
                    </Grid>
                </Grid>
            </Grid>
        )
    });
