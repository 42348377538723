import React from 'react'; // we need this to make JSX 
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { ReactComponent as CircleIcon } from '../../../assets/svg/link-circle.svg';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { ContactUsModal } from './ContactUsModal';
/**
 * Component: Copyright blobk
 * Usage:Copyright for login related pages
 */

Logger.debug("Copyright.tsx", "Copyright initializing")

const Copyright = (): JSX.Element => {

    const [openModal, setOpenModal] = React.useState(false);

    /**
     * Method to open the Modal
     */
    const handleContactUsModalOpen = () => {
        setOpenModal(true);
    }

    /**
     * Method to close the Modal
     */
    const handleContactUsModalClose = () => {
        setOpenModal(false);
    }
    
    return (
        <Grid className="login-page-bottom-copyright" xs={12}>
            © {(new Date().getFullYear())} Priority Passport
            <span>
                <CircleIcon />
                 {/*eslint-disable-next-line*/}
                <a target="_blank" id="terms-of-service" href="https://prioritycommerce.com/enterprise-payments/passport/legal-terms/"> Passport Privacy & Terms </a>
            </span>
            <span>
                <CircleIcon />
                {/*eslint-disable-next-line*/}
                <a target="_blank" id="terms-of-service" onClick={handleContactUsModalOpen}> Contact Us </a>
            </span>

            {/** Modal for  Contact Us */}
            <FxModalGeneric
                id={"edit-account-nickname"}
                open={openModal}
                onClose={handleContactUsModalClose}
                componentProps={{ }}
                className={'fx-modal-small2medium'}
                component={ContactUsModal}
            />
        </Grid>
    );
};

export default Copyright;
