import React from 'react';
import { Grid, Typography } from "@mui/material";
import { FxLink } from '../Action/FxLink';

/**
* Method handles the JSX of batch number
* @param item : ledger data
* @param isSmallScreen : to check for small screen to return different UI for different screen
* @returns : Batch number JSX
*/
export function getBatchNumberJsx(item: any, isSmallScreen: boolean, handleTabClick:any) {
    let batchNumber = getBatchNumber(item);
    if(isSmallScreen) {
        return (batchNumber) && <Grid item>
        <Grid><Typography variant='h5'><FxLink className='fx-batch-id' onClick={handleTabClick}>Batch Id: {batchNumber}</FxLink></Typography></Grid>
    </Grid>
    } else {
        return (batchNumber) ? <FxLink className='fx-batch-id' onClick={handleTabClick}>{batchNumber}</FxLink> : '-';
    }
}

/**
* Method handles to get the Batch number
* @param item : ledger data
* @returns : Batch number if exists or undefiend
*/
export function getBatchNumber(item: any) { return item.batchNumber ? item.batchNumber : undefined; }


/**
* Method handles the JSX of batch id
* @param item : ledger data
* @param isSmallScreen : to check for small screen to return different UI for different screen
* @returns : Batch Id JSX
*/
export function getBatchIdJsx(item: any, isSmallScreen: boolean, handleTabClick:any) {
    let batchId = item.cardBatchId;
    if(isSmallScreen) {
        return (batchId) && <Grid item>
        <Grid><Typography variant='h5'><FxLink className='fx-batch-id' onClick={handleTabClick}>Batch Id: {batchId}</FxLink></Typography></Grid>
    </Grid>
    } else {
        return ((batchId) ? <FxLink className='fx-batch-id' onClick={handleTabClick}>{batchId}</FxLink> : '-');
    }
}

/**
* Method handles to get the trim batch id (high priority) or Batch Id
* @param item : ledger data
* @returns : Batch Id if exists or undefiend
*/
export function getBatchId(item: any) { return item?.trimmedBatchId ? item.trimmedBatchId : item.batchId }

