/* eslint-disable no-useless-computed-key */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Logger } from '../../../../libs/utils/logger';
import HttpClient from '../../../../libs/utils/httpClient';
import { IFileInfo } from '../../../Data/FxAgreementLinkCard';
import { getLastFourDigits } from '../../../Utils/CommonBaseClass';
import { ReviewAndSubmitSignup } from '../Common/ReviewAndSubmitSignup';
import { AccountIdentifierSignup } from '../Common/AccountIdentifierSignup';
import { ICardData } from '../Common/ReviewInfoCard';
import { BusinessDetailsSignup } from './BusinessDetailsSignup';
import { BusinessLeftBarSignup } from './BusinessLeftBarSignup';
import { getCustomerUrl, processAPIResponse, pushGTM } from '../../../../libs/utils/utils';
import { getKey, removeCookieCustom, setKey } from '../../../../libs/utils/storageManager';
import { BusinessOwnerCard } from './BusinessOwner/BusinessOwnerCard';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { convertToPhoneDisplayFormat, clean, updateComponentData, fetchDataFromState, updateComponentParams, callAPI, Method} from '../../../Utils/CommonBaseClass';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { useDispatch, useSelector } from 'react-redux';
import { LocationDetailsSignupCard } from '../../Location/LocationDetailsSignupCard';
const httpClient = HttpClient.getClient();

Logger.debug("BusinessUserSignup.tsx", "Business User Signup initializing")

/**
 * Component: BusinessUserSignup
 */
export const BusinessUserSignup: React.FC<any> = React.memo(
    (props) => {
       
        const history = useHistory()
        const dispatch = useDispatch();
        let context : any;
        ({ context, props } = RegisterComponent(props));
        const { mainMenu, isLoading, paymentIdDetailReq, initialUserData, initialBOData, isUpdateContactRequest, formReq, locationDetailReq  } = props;

        useEffect(() => {
            updateComponentParams(dispatch, props.id, { mainMenu: 1, isLoading: false, paymentIdDetailReq: undefined, initialUserData: false, initialBOData: false, isUpdateContactRequest: false, formReq: undefined, locationDetailReq: undefined    })
        },[dispatch,props.id])
 
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })

        /**
         * Method fetches data from the state
         */
        const businessState = useSelector((state: any) =>
            fetchDataFromState(state, 'customer-kyc-data')
        );

        // Method handles the click event of the left menu
        const handleMenuClick = (main: any) => {
            updateComponentParams(dispatch, props.id, { mainMenu: main })
        }

        // Method handles the onsubmit event of the business details page
        const handleBusinessDetailsSubmit = async (data: any, req: any, callback:any) => {
            const businessStateCopy =  {...businessState?.data};
            businessStateCopy['businessDetailsData'] = data;
            businessStateCopy['businessDetailsReq'] = req;
            updateComponentData(dispatch, 'customer-kyc-data', { ...businessStateCopy })
            const formReqCopy = {...formReq}
            let newFormReq: any;
            if(formReqCopy){
                formReqCopy['businessDetailsReq'] = req;
            }
            else{
                newFormReq = { businessDetailsReq: req }
            }


            const request = {
                type: "BUSINESS",
                business: req               
            }
          
            let url:any="customer/id/" + getKey('customerId');
            let status = await callAPI(url, Method.POST, request)
            status = processAPIResponse(status)
            callback()
            if (status.status) {
                updateComponentParams(dispatch, props.id, { mainMenu: mainMenu+1, formReq: formReqCopy || newFormReq })
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }            
        }
       


       /**
        * method used to handle pre-populating customer info
        */
        const setInitialData= ()=> {
            let businessDetailsData: any;
            let beneficialOwnerData: any;
            let detailedBusinessInfo: any = context?.data?.body?.business;
            if(!initialUserData){
                const businessDetailsDataFromApi =  getBusinessDetailsDataFromApi(detailedBusinessInfo);
                businessDetailsData = businessState?.data?.businessDetailsData || businessDetailsDataFromApi;
            }

            if(!initialBOData){
               const data =  getBODetailsDataFromApi(detailedBusinessInfo.beneficialOwner !== undefined ? detailedBusinessInfo.beneficialOwner :[]);
                beneficialOwnerData = businessState?.data?.beneficialOwnerData?.length > 0? businessState?.data?.beneficialOwnerData: data;
            }
            let businessCopy: any = businessState?.data ? { ...businessState.data } : {};
            let businessObj;
            if(businessCopy){
                if(businessDetailsData){
                    businessCopy['businessDetailsData'] = businessDetailsData
                }
                if(beneficialOwnerData){
                    businessCopy['beneficialOwnerData'] = beneficialOwnerData
                }
            }
            else{
                businessObj = { businessDetailsData, beneficialOwnerData  }
            }
            
            getLocationDetails();
            
            updateComponentData(dispatch, 'customer-kyc-data', { ...businessCopy || businessObj })
            updateComponentParams(dispatch, props.id, { initialUserData: true, initialBOData: true })

            
        }

        const getLocationDetails = async () => {
            const listRequest = {
                pageNumber: 1,
                pageSize: 25,
                sortOptions: {
                    sortBy: "id",
                    sortOrder: "asc"
                },
                criteria: {
                    filters: [
                        {
                            operator: "eq",
                            key: "status",
                            values: [
                                "ACTIVE"
                            ]
                        }
                    ]
                }
            }
            const locationList: any = await httpClient.post(getCustomerUrl('location/list', false), listRequest).then((response) => response.data)
            updateComponentParams(dispatch,props?.id,{locationDetailReq: locationList?.resources })
            return locationList;
        }

        /**
         * method used to convert the customer api response in form data format
         * @param data :data from the a[i response
         * @returns data format for form data
         */
        function getBusinessDetailsDataFromApi(data: any) {
            let _mailingAddress: any = data.mailingAddress;
            _mailingAddress = _mailingAddress ? _mailingAddress[0] : {};
            return {
                "phone": data.phone ? convertToPhoneDisplayFormat(data.phone) : '',
                "legalName": data.legalName ? data.legalName : '',
                "email": data.email ? data.email : '',
                "businessCategory": data.businessCategory ? data.businessCategory : '',
                "ein": data.ein ? data.ein : '',
                "doingBusinessAs": data.doingBusinessAs ? data.doingBusinessAs : '',
                "dateOfIncorporation": data.dateOfIncorporation ? data.dateOfIncorporation : '',
                "stateOfIncorporation": data.stateOfIncorporation ? data.stateOfIncorporation : '',
                "description":data.description ? data.description : '',
                 "website":data.website ? data.website : '',
                "addressLine1": _mailingAddress.addressLine1 ? _mailingAddress.addressLine1 : '',
                "addressLine2": _mailingAddress.addressLine2 ? _mailingAddress.addressLine2 : '',
                "city": _mailingAddress.city ? _mailingAddress.city : '',
                "state": _mailingAddress.state ? _mailingAddress.state : '',
                "zip": _mailingAddress.zip ? _mailingAddress.zip : '',
                "address_id": _mailingAddress.id ? _mailingAddress.id : '',
            }
        }

        /**
         * method used to convert customer get  api response in form data format
         * @param data :data from api response
         * @returns
         */
        function getBODetailsDataFromApi(data: any) {
               return data.length ? data.map((data: any) => {
                    let _mailingAddress: any = data.mailingAddress;
                    _mailingAddress = _mailingAddress ? _mailingAddress[0] : {};
                      return {
                            "id":data.id?data.id:data.id,
                            "firstName": data.firstName ? data.firstName : '',
                            "lastName": data.lastName ? data.lastName : '',
                            "email": data.email ? data.email : '',
                            "jobtitle": data.businessDetails.title ? data.businessDetails.title : '',
                            "ownerShipPercentage": data.businessDetails.ownershipPercentage ? data.businessDetails.ownershipPercentage : '',
                            "dob": data.dob ? data.dob : '',
                            "ssn": data?.ssn || data?.last4ssn || '',
                            "itin": data?.taxIdentification?.id || data?.taxIdentification?.idLast4 || '', 
                            // "usCitizen": data.isUSCitizen ? data.usCitizen : '',
                            "secondaryIdentificationType": data.secondaryIdentification.type ? data.secondaryIdentification.type : '',
                            "description":data.description ? data.description : '',
                               "secondaryIdentificationId": data.secondaryIdentification.id ? data.secondaryIdentification.id : '',
                             "secondaryIdentificationIssuance":data.secondaryIdentification.countryOfIssuance ? data.secondaryIdentification.countryOfIssuance:'',
                             "secondaryIdentificationIssuanceAlien":data.secondaryIdentification.secondaryIdentificationIssuanceAlien ? data.secondaryIdentification.secondaryIdentificationIssuanceAlien:'',
                             "secondaryIdentificationIssuanceState": data.secondaryIdentification.stateOfIssuance ? data.secondaryIdentification.stateOfIssuance : '',
                            "address_id": _mailingAddress.id ? _mailingAddress.id : '',
                             "addressLine1": _mailingAddress.addressLine1 ? _mailingAddress.addressLine1 : '',
                            "addressLine2": _mailingAddress.addressLine2 ? _mailingAddress.addressLine2 : '',
                            "city": _mailingAddress.city ? _mailingAddress.city : '',
                            "state": _mailingAddress.state ? _mailingAddress.state : '',
                            "zip": _mailingAddress.zip ? _mailingAddress.zip : '',
                            "mobilePhone": data.mobilePhone ? convertToPhoneDisplayFormat(data.mobilePhone) : '',
                            "homePhone": data.homePhone ? convertToPhoneDisplayFormat(data.homePhone) : '',
                            "workPhone": data.workPhone ? convertToPhoneDisplayFormat(data.workPhone) : '',
                            "authSignatory":data.actAsAuthorizedSignatory && data.actAsAuthorizedSignatory
                          }
                }) : []
        }

        // Method handles the onsubmit event of the beneficial owner page
        const handleBeneficialOwnerSubmit = (data: any, req: any, action: any, index: any) => {
            let benOwnerData = [...businessState?.data?.beneficialOwnerData]
            let benOwnerReq = businessState?.data?.beneficialOwnerReq?.length > 0? [...businessState?.data?.beneficialOwnerReq]: []
            let reqArray: any;
            let dataArray: any;

            if (req?.actAsAuthorizedSignatory === true && benOwnerReq.length > 0) {
                const updatedBenOwnerReq = benOwnerReq.map((item: any) => {
                    return {
                      ...item,
                      actAsAuthorizedSignatory: false
                    };
                  });
                benOwnerReq = updatedBenOwnerReq;
                const updatedBenOwnerData = benOwnerData.map((item: any) => {
                    return {
                      ...item,
                      authSignatory: false
                    };
                  });
                benOwnerData = updatedBenOwnerData;


                if (action === 'edit' && benOwnerReq?.length > 0 && benOwnerReq[index]) {
                    benOwnerReq[index].actAsAuthorizedSignatory = true;
                }
            }
            if (action === 'add') {
                reqArray = [...benOwnerReq, req];
                dataArray = [...benOwnerData, data];
            }
            else {
                if (index !== undefined) {
                    reqArray = [...benOwnerReq];
                    dataArray = [...benOwnerData];
                    reqArray[index] = req;
                    dataArray[index] = data;
                }
            }
            const dataCopy = {...businessState?.data}
            dataCopy['beneficialOwnerData'] = dataArray;
            dataCopy['beneficialOwnerReq'] = reqArray;
            updateComponentData(dispatch, 'customer-kyc-data', { ...dataCopy  })

        }

        /**
        checking customer info is present on the customer get api or not.If data is present then calling method 'setInitialData' to prepolutae the information
         */
        
        useEffect(() => {
            if(context?.data?.body){
                setInitialData();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[context?.data?.body])
        /**
         * update request and data after remove business owner
         * @param index :index
         * @param data :data
         */
        const updatedBusinessOwnerData= (index:any,data:any) =>{
            const reqArray = [...businessState?.data?.beneficialOwnerReq];
            reqArray.splice(index, 1);
            const dataCopy = {...businessState?.data}
            dataCopy['beneficialOwnerData'] = data;
            dataCopy['beneficialOwnerReq'] = reqArray;
            updateComponentData(dispatch, 'customer-kyc-data', { ...dataCopy  })
        }

        // Method handles the onsubmit event of the review & submit page
        const handleReviewBusinessSubmit = (req: any) => {
            const formReqCopy = {...formReq};
            formReqCopy['ReviewBusinessReq'] = req;
            updateComponentParams(dispatch, props.id, { formReq: formReqCopy })
            reviewBusinessSubmitRequest(req);
        }

        async function reviewBusinessSubmitRequest(req:any){
            let status:any = false;
            if(isUpdateContactRequest){
                status = true;
            }

            if(!status){
                status = await transformRequest(req)
            }

           if(status && getKey('isPPIEnabled') === 'TRUE'){
                const payload ={
                    ppi:paymentIdDetailReq.ppi?.trim()
                };

                let ppIdStatus = await addPaymentId(payload);
                ppIdStatus = processAPIResponse(ppIdStatus)
                if(ppIdStatus.status){
                    setTimeout(() => {
                        history.push('/')
                    }, 1000);
                }
                else{
                    //api  failed
                    FxSnackBar.show({
                        text: ppIdStatus.message,
                    });
                }
           }
        }
        /**
         * Deep copy function to create a copy of the object and its nested properties
         */
        function deepCopy(obj: any) {
            if (typeof obj !== 'object' || obj === null) {
            return obj; // Return the value if it's not an object
            }
        
            const newObj: any = Array.isArray(obj) ? [] : {}; // Determine if it's an array or object
        
            for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = deepCopy(obj[key]); // Recursively copy nested properties
            }
            }
        
            return newObj;
        }

        // Method transforms the request from various pages for api call
        async function transformRequest(accountInfo:any){

          const linkedDocuments = accountInfo?.fileData?.map((file: IFileInfo) => {
            return {
              purpose: 'AUTHORIZATION',
              document: {
                type: file?.fileType,
                name: file?.fileType === 'RATE_CARD' ? 'RATE_CARD.pdf' : 'KYC_AGREEMENT.pdf',
                base64encodedContent: file?.base64
              }
            }
          })

          let accountData: any = {
                "isCustomerOwned": true,
                "linkedDocument": linkedDocuments
            }
          if(paymentIdDetailReq?.nickname){
            accountData = {
              ...accountData,
              "nickName" : paymentIdDetailReq?.nickname
            }
          }
            let benOwnerReq = [...businessState?.data?.beneficialOwnerReq];
            const updatedBenOwnerReq = benOwnerReq.map((item: any) => {
                const { address_id, ...rest } = item || {};
                return rest;
              });
            benOwnerReq = updatedBenOwnerReq;
            let formReqCopy = deepCopy(formReq);
            formReqCopy['businessDetailsReq']['beneficialOwner'] = benOwnerReq
            formReqCopy['businessDetailsReq']['dateOfIncorporation'] = moment(formReqCopy['businessDetailsReq']['dateOfIncorporation']).format('MM/DD/YYYY')
            if(formReqCopy['businessDetailsReq']['address_id']){ //need to check == ""
            delete formReqCopy['businessDetailsReq']['address_id']
            }
            let req:any = {
                customer:{
                    type: "BUSINESS",
                    //business: {...formReqCopy['businessDetailsReq']},
                },
                account:accountData

            }
            updateComponentParams(dispatch, props.id, { isLoading: true })
            let status: any;
            
           
            req = clean(req);
            status = await updateCustomer(req);
            status = processAPIResponse(status)
            if (status.status) {
                removeCookieCustom('customerKycData')
                // configuring gtm for kyc successful submit
                    const gtmData ={
                        event:"userKycSuccessTrigger",
                        "userEmail": getKey('email'),
                        "userType": "BUSINESS"
                    }

                    pushGTM(gtmData)
                    setKey('kycPending','true');
                    if(getKey('isPPIEnabled') === 'TRUE'){
                        updateComponentParams(dispatch, props.id, { isUpdateContactRequest: true })
                        return true;
                    }
                    else{
                        updateComponentParams(dispatch, props.id, { isLoading: false, isUpdateContactRequest: true })
                        setTimeout(() => {
                            history.push('/')
                        }, 1000);
                    }


            }
            else {
                //api  failed
                updateComponentParams(dispatch, props.id, { isLoading: false })
                let error_text =  status.message
                 if (req['customer']['business']  && req['customer']['business']['beneficialOwner'] && req['customer']['business']['beneficialOwner'][0]['actAsAuthorizedSignatory'] === "false" && req['customer']['business']['businessCategory'] === 'SOLE_PROPRIETOR') {
                    error_text = 'Authorized Signatory is required for business category Sole Proprietor'
                }
                FxSnackBar.show({
                    text: error_text,
                });

                return false;
            }

        }

        /**
         * API call function
         * @param req :request
         * @returns
         */
        async function updateCustomer(req: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('/onboard',false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }

        }

         /**
         * API call function for create ppi
         * @param req :request
         * @returns
         */
        async function addPaymentId(req: any) {
            try {
                const url = "/ppi"
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }

        }

        // Method handles the click event of the beneficial owner page to move to the next screen
        const handleNextScreen = async (callback:any) => {

            let benOwnerReq = [...businessState?.data?.beneficialOwnerReq];

            const updatedBenOwnerReq = benOwnerReq.map((item: any) => {
                const { address_id, ...rest } = item || {};
                return rest;
              });

            benOwnerReq = updatedBenOwnerReq; 
         /**
         *  To update beneficial owners by removing 'ssn' property for items with SSN length less than 10.
         * @param {object} req - The request object containing beneficial owner information.
         * @returns {Array} - An array of updated beneficial owners.
         */
            const updatedBeneficialOwners = (benOwnerReq || []).map((item: any) => {
                if (item?.ssn && item.ssn.length < 10) {
                    // Create a new object without the 'ssn' property
                    const { ssn, ...updatedItem } = item;
                    return updatedItem;
                } else {
                    if(item.ssn === ''){
                        delete item.ssn;
                    }
                    return item;
                }
            });
            benOwnerReq = updatedBeneficialOwners;
            let businessDetailsReq = {
                beneficialOwner:benOwnerReq
            };
           
            let req:any = {
                type: "BUSINESS",
                business: {...businessDetailsReq}, 
            }

            let url:any="customer/id/" + getKey('customerId');
            let status = await callAPI(url, Method.POST, req)
            status = processAPIResponse(status)
            callback()
            if (status.status) {
                let mainMenuVar: number;

                if(getKey('isPPIEnabled') === 'TRUE'){
                    mainMenuVar = mainMenu+1;
                }
                else{
                    mainMenuVar = mainMenu+2;
                }

                updateComponentParams(dispatch, props.id, { mainMenu: mainMenuVar })
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }          

        }

        // Method handles the click event to move to the previous screen
        const handleGoBack = (main: any) => {
            updateComponentParams(dispatch, props.id, { mainMenu: main })
        }
         // Method handles the click event to move to the previous screen for review
         const handleGoBackReview = (step?:number) => {
          if(step){
              updateComponentParams(dispatch, props.id, { mainMenu: step })
          } else {
            let mainMenu: number;
            if(getKey('isPPIEnabled') === 'TRUE'){
              mainMenu = 4;
            }
            else{
              mainMenu = 4 - 1;
            }
            updateComponentParams(dispatch, props.id, { mainMenu })
          }
        }


        // Method handles to going for review screen to individual details screen
        const handlePaymentDetailsSubmit = (req: any) => {
            let mainMenuVar: number = mainMenu+1;
            updateComponentParams(dispatch, props.id, { mainMenu: mainMenuVar , paymentIdDetailReq: req })

        }

        /**
         * Method for submitting Location Details
         */
        const onLocationDetailSubmit = () => {
            let mainMenuVar: number = mainMenu+1;
            updateComponentParams(dispatch, props.id, { mainMenu: mainMenuVar})
            getLocationDetails();
        }

      const businessData = businessState?.data?.businessDetailsData || {};
      const beneficialOwnersData = businessState?.data?.beneficialOwnerData || [];

      // business details for the review screen
      const basicBusinessInfo: ICardData = {
        header: 'Business Information',
        items: [{
          name: `${businessData.firstName ?? ''} ${businessData.lastName ?? ''}`,
          data: {
            ein: {
              title: 'EIN:',
              value: businessData.ein || ''
            },
            address: {
              value: `${businessData.addressLine1 || ''} ${businessData.addressLine2 || ''}${businessData.city ? ', ' + businessData.city : ''}${businessData.state ? ', ' + businessData.state : ''}${businessData.zip ? ', ZIP - ' + businessData.zip : ''}`
            },
            email: {
              value: businessData.email || ''
            }
          }
        }]
      }


      // Owner details for the review screen
      const businessOwnerData = {
        header: 'Business Owner & Auth Signatory Info',
        items: beneficialOwnersData.map((owner: any, index: number) => {
          const ssn = getLastFourDigits(owner.ssn);
            return {
              name: `${owner.firstName ?? ''} ${owner.lastName ?? ''} (Ownership ${owner.ownerShipPercentage}%)`,
              index: index + 1,
              data: {
                authSignatory: {
                  value: owner.authSignatory === true
                },
                email: {
                  value: owner.email || ''
                },
                ssn: {
                  title: 'SSN',
                  value: `${ssn ? '*' + ssn : ''}`
                },
                dob: {
                  title: 'DOB',
                  value: owner.dob || ''
                },
                address: {
                  value: `${owner.addressLine1 || ''} ${owner.addressLine2 || ''}${owner.city ? ', ' + owner.city : ''}${owner.state ? ', ' + owner.state : ''}${owner.zip ? ', ZIP - ' + owner.zip : ''}`
                }

              }
            }
          }
        )
      }

      // Account identifier details for the review screen
      const { phone, email } = businessData || {};
      const { value, ppi } = paymentIdDetailReq?.paymentType || {};


      const accountIdentifierData = {
        'Phone': {
          title: 'Phone:',
          value: phone || ''
        },
        'Email': {
          title: 'Email:',
          value: email || ''
        },
        'Custom': {
          title: 'PPI ID:',
          value: ppi || ''
        }
      }


      const accountIdentifierInfo: ICardData = {
        header: 'Account Identifier',
        items: [{
          name: paymentIdDetailReq?.nickname || '',
          data: {
            ppi: accountIdentifierData[value as keyof typeof accountIdentifierData] || {},
          }
        }]
      }


      // Review details for the review screen
      const reviewDetails = [
        { info: basicBusinessInfo, step: 1 },
        { info: businessOwnerData, step: 3 },
        { info: accountIdentifierInfo, step: 4 }
      ]


      return (
            <Grid id="business-user-signup-grid" item container xs={12}  className='fx-form-grid'>
                {!isSmallScreen && <Grid item xs={3} sm={4} md={3} className ="fx-login-promo-parent">
                    <BusinessLeftBarSignup activeMainMenu={mainMenu} onClick={handleMenuClick} data={businessState?.data}></BusinessLeftBarSignup>
                </Grid>}
                {isSmallScreen && <Grid item xs={12} sm={12} className ="fx-login-promo-parent">
                    <BusinessLeftBarSignup activeMainMenu={mainMenu} onClick={handleMenuClick} data={businessState?.data}></BusinessLeftBarSignup>
                </Grid>}
                
                <Grid item xs={isSmallScreen? 12: 9} sm={isSmallScreen?12: 8} md={isSmallScreen?12: 9}>
                    {mainMenu === 1  && initialUserData && businessState?.data && <BusinessDetailsSignup id="business-details-signup" data={businessState?.data?.businessDetailsData} onSubmit={handleBusinessDetailsSubmit}/>}
                    {mainMenu === 2 && <LocationDetailsSignupCard id="location-details-signup" businessDetails={businessState?.data?.['businessDetailsData']} data={locationDetailReq} onSubmit={onLocationDetailSubmit} goBackClick={handleGoBack} />}
                    {mainMenu === 3 && initialBOData && <BusinessOwnerCard businessDetails={businessState?.data?.['businessDetailsData']} updatedData={updatedBusinessOwnerData} data={businessState?.data?.beneficialOwnerData} onClick={handleNextScreen} goBackClick={handleGoBack} onSubmit={handleBeneficialOwnerSubmit}/>}
                    {mainMenu === 4 && (getKey('isPPIEnabled') === 'TRUE') && businessState?.data && <AccountIdentifierSignup details={businessState?.data?.businessDetailsData} onSubmit={handlePaymentDetailsSubmit} data={paymentIdDetailReq} goBackClick={handleGoBack} />}
                    {mainMenu === 5 && <ReviewAndSubmitSignup reviewDetails={reviewDetails} goBackClick={handleGoBackReview} onSubmit={handleReviewBusinessSubmit} loading={isLoading}/>}
                </Grid>
            </Grid>
        )
    })
