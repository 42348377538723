import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { AUTH_STRINGS } from '../../../../../constants/strings';
import FxCard from '../../../../Container/FxCard';
import FxCardBody from '../../../../Container/FxCardBody';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxTextEdit } from '../../../../Input/FxText/FxTextEdit';
import FxLabelView from '../../../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../../../Action/FxButton';
import usa_state from '../../../../Utils/usa_states.json'
import { deleteUnwantedRequest, handleKeyDownChange, handleZipChange, handleAddressChange, renderError, setZipValidationOptions, setPhoneValidationOptions, convertToPhoneRequestFormat, renderPhoneError } from '../../../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../../../Input/FxPhone/FxPhoneEdit';
import { FxTextEditSSN } from '../../../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../../../Input/FxSelect/FxSelectAutoSearch';

//component used to edit joint tenacny co owner

export const JointTenancyCoOwnerEdit: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();       
        const [checkBoxValue, setCheckBoxValues] = useState<any>({ creditReport: false })
        const [businessOwnerDetails, setBusinessOwnerDetails] = useState<any>();
        /**
        * Function to be called before loading the component
        */
        useEffect(() => {
            if (props.data) {
                setBusinessOwnerDetails(props?.data);               
                setCheckBoxValues({ creditReport: props?.data?.creditReport === undefined ? false : props?.data?.creditReport === 'creditReport' ? true : props?.data?.creditReport ? props?.data?.creditReport : false })
            }
        }, [props?.data]);              


        // let creditReportCheckBoxData = [
        //     { label: 'Pull Credit Report', value: 'creditReport', checked: checkBoxValue?.creditReport }
        // ]

        // Email validation constant
        const setEmailValidationOptions = {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };

        // Ssn validation constant
        const setSsnValidationOptions = {
            required: true,
            maxLength: 11,
            pattern: {
                value: /^\d{3}-?\d{2}-?\d{4}$/,
                message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
            },
        };     

        // Method handles the submit for edit beneficial owner form
        function onSubmit(data: any) {
          data.ssn = data?.ssn || businessOwnerDetails?.ssn;
            const req: any = { ...data };
            if (data['id'] === '') {
                delete data['id'];
                delete req['id'];
            }
            if (data['dob']) {
                req['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            const address: any = {
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip'],
                "isPrimary": true,
            }
            if (data['address_id']) {
                address['id'] = data['address_id'];
            }
            req['mailingAddress'] = [address]
            req.portalAccess = {
                grantAccess: true
            };
            
            req['mobilePhone'] = convertToPhoneRequestFormat(req.mobilePhone)
            if (req['mobilePhone'] === "") {
                delete req['mobilePhone'];
            }
            if (data) {
                data['creditReport'] = checkBoxValue.creditReport
            }
            // req = clean(req);
            const unwantedRequestArray = ['state', 'addressLine1', 'city', 'zip', 'addressLine2', 'creditReport','mobilePhone-select'];
            deleteUnwantedRequest(req, unwantedRequestArray)
            if (props.onSubmit) {
                props.onSubmit(data, req, 'edit', props?.index)
            }
            if (props?.cancel) {
                props.cancel()
            }
        }

        // Method handles the click operation for going back to business owner list screen
        const handleGoBackClick = (data: any) => {
            if (props?.cancel) {
                props.cancel()
            }
        }
        /**
        *  Address line 1 validation constant
        * */
        const setAddressLine1Validation = {
            required: true,
            minLength: {
                value: 4,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            },
            maxLength: {
                value: 40,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            }
        }

        return (
            <Grid container id='signUp-business-owner' xs={12}>
                <FxCard id="signUp-business-owner-form-card" className="fx-info-kyc-edit">
                    <div className="fx-form-edit-profile">
                        {businessOwnerDetails !== undefined && <form id="signUp-business-owner-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCardBody id="signUp-business-owner-form-card-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                    <FxTextEdit register={{ ...register("id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-first-name" name="id" variant="outlined" defaultValue={businessOwnerDetails?.id} />
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-first-name" label="First Name*" name="firstName" variant="outlined" defaultValue={businessOwnerDetails?.firstName} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("middleName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-middle-name" label="Middle Name" name="middleName" variant="outlined" defaultValue={businessOwnerDetails?.middleName} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("lastName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-last-name" label="Last Name" name="lastName" variant="outlined" defaultValue={businessOwnerDetails?.lastName} />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-dob" label="Date of Birth*" name="dob" variant="outlined" defaultValue={businessOwnerDetails?.dob} maxDate={moment()}  setValue={setValue}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEditSSN
                                            register={{ ...register("ssn") }}
                                            className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            error={errors?.ssn}
                                            control={control}
                                            rules={(businessOwnerDetails?.itin) ? {required: false, disabled: true} :setSsnValidationOptions}
                                            id="signUp-business-owner-form-card-ssn"
                                            label={`Social Security Number ${(businessOwnerDetails?.itin) ? '': '*'}`}
                                            placeholder="XXX-XX-XXXX"
                                            name="ssn"
                                            variant="outlined"
                                            defaultValue={businessOwnerDetails?.ssn}
                                            setValue={setValue}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />
                                        <div className={'error-message'}>
                                            {errors.ssn && renderError(errors.ssn)}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >PRIMARY ADDRESS</FxLabelView>
                                    </Grid>
                                    <FxTextEdit register={{ ...register("address_id") }} className={"fx-hidden"} control={control} rules={{ required: false }} id="signUp-business-owner-form-card-first-name" name="address_id" variant="outlined" defaultValue={businessOwnerDetails?.address_id} />
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAddressLine1Validation} id="signUp-business-owner-addressLine1" label="Address Line 1 *" name="addressLine1" defaultValue={businessOwnerDetails?.addressLine1} onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                        <div className={'error-message'}>
                                            {errors.addressLine1 && renderError(errors.addressLine1)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-addressLine2" label="Address Line 2" name="addressLine2" defaultValue={businessOwnerDetails?.addressLine2} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-city" label="City *" name="city" defaultValue={businessOwnerDetails?.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-business-owner-select-state" name="state" data={usa_state} label="State *" setValue={setValue} value={businessOwnerDetails?.state} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="signUp-business-owner-zip" label="ZIP *" name="zip" defaultValue={businessOwnerDetails?.zip} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                        <div className={'error-message'}>
                                            {errors.zip && renderError(errors.zip)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxPhoneEdit register={register} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id="signUp-business-owner-form-card-mobilePhone" label="Mobile Phone *" name="mobilePhone" placeholder={'XXX-XXX-XXXX'} defaultValue={businessOwnerDetails?.mobilePhone} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.mobilePhone && renderPhoneError(errors.mobilePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions} id="signUp-business-owner-form-card-email" label="Email*" name="email" variant="outlined" defaultValue={businessOwnerDetails?.email} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="signUp-business-owner-Card-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton
                                        disableRipple={false}
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="back-edit-business-owner-btn"
                                        onClick={handleGoBackClick}
                                    > Cancel
                                    </FxButton>
                                    <Grid className='fx-padding-right-15'></Grid>
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-edit-business-owner-btn"
                                        type="submit"
                                    > SAVE CHANGES
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>}
                    </div>
                </FxCard>
            </Grid>
        )
    }
)
