/**
 * Component: EditLeadsInvite
 * Usage: Component handles the edit invitation creation for leads
 */
import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import FxRadioEdit from '../../../Input/FxRadio/FxRadioEdit';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import FxPhoneEdit from '../../../Input/FxPhone/FxPhoneEdit';
import {
  ageLimit,
  convertToPhoneDisplayFormat,
  convertToPhoneRequestFormat,
  deeplyClean,
  fetchDataFromState,
  handleKeyDownChange,
  handleZipChange,
  parseJsonData,
  renderError,
  renderOwnershipPercentageError,
  renderPhoneError,
  setEinValidationOptions,
  setPhoneValidationOptions,
  setSsnValidationOptions,
  setZipValidationOptions,
  updateComponentData,
  updateComponentParams,
  renderCityNameError,
  setCityNameValidation
} from '../../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../../Input/FxDate/FxDateEdit';
import { FxTextEditSSN } from '../../../Input/FxSSN/FxTextEditSSN';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import usa_state from '../../../Utils/usa_states.json';
import { FxSwitch } from '../../../Action/FxSwitch';
import FxLabel from '../../../Input/FxLabel/FxLabelView';
import { useHistory } from 'react-router';
import { FxSelectAutoSearch } from '../../../Input/FxSelect/FxSelectAutoSearch';
import { getKey } from '../../../../libs/utils/storageManager';
import { ViewBeneficialOwner } from './ViewBeneficialOwner';
import HttpClient from '../../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import moment from 'moment';
const httpClient = HttpClient.getClient();


Logger.debug("CreateNewInvite.tsx", "Create new invite initializing");


const shareViaData = [
    { label: 'Email', value: 'EMAIL' },
    { label: 'SMS', value: 'SMS' }
]
const customerTypeData = [
    { label: 'Business', value: 'BUSINESS' },
    { label: 'Individual', value: 'INDIVIDUAL' }
]
const categoryData = [
    { value: "SOLE_PROPRIETOR", label: "Sole Proprietor" },
    { value: "PARTNERSHIP", label: " Partnership" },
    { value: "LLC", label: "LLC" },
    { value: "LLP", label: "LLP" },
    { value: "C_CORP", label: "C Corp" },
    { value: "S_CORP", label: "S Corp" },
    { value: "SINGLE_MEMBER_LLC", label: "Single member LLC" },
    { value: "GOVERNMENT_ORGANISATION", label: "Govt. (LocalState/Federal)" },
    { value: "TAX_EXEMPTION", label: "501 c/Tax Exempt" },
    { value: "NON_PROFIT", label: "Non Profit" }
]

export const EditLeadsInvite: React.FC<any> = React.memo((props) => {

    let context: any;
    ({ context,props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const methods = useForm();
    const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, clearErrors, setError, control, watch, reset, trigger } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "beneficialOwner"
    });
   

    const history = useHistory();
  
    

    /**
     * Useeffect hook handles the function on load
     */
    useEffect(() => {
        reset()
        updateComponentData(dispatch,"lead-invite-prefiled",{})
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'partner-beneficial-owner', data: { collapseAdd: false, collapseEdit: false } } });
        setValue('setAsPrimary', false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * Get prefilled information
     */
    const prefilledData = useSelector((state: any) =>
        fetchDataFromState(state, 'lead-invite-prefiled')
    );

    /**
     * Function to set values for the form using React Hook Form's setValue method
     * @param data : data object
     */
    const setFormValues = (data: any) => {
        Object.entries(data).forEach(([key, value]) => {
            setValue(key, value); // Set each key-value pair in the form
        });
    };
    /**
     * Parses JSON data and returns the parsed object.
     * 
     * @param {string} jsonData - The JSON data to be parsed.
     * @returns {Object} The parsed JSON object.
     */
   
    useEffect(() => {
        
        if(prefilledData?.data){
            let filledData:any=parseJsonData(prefilledData?.data)
        setFormValues(filledData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[prefilledData?.data])
  
    
    /**
     * Constructs an array containing a mailing address object.
     * @param address - The address object containing address details.
     * @param isPrimary - A boolean flag indicating if the address is primary.
     * @returns An array containing an object representing a mailing address.
     */
    const getMailingAddress = (address: any, isPrimary: boolean) => {
        return [
            {
                "addressLine1": address.addressLine1 ? address.addressLine1 : '',
                "addressLine2": address.addressLine2 ? address.addressLine2 : '',
                "city": address.city ? address.city : '',
                "state": address.state ? address.state : '',
                "zip": address.zip ? address.zip : '',
                "isPrimary": isPrimary,
                "id":address?.addressId
            }
        ];
    }
    /*
    This function takes an address object and a boolean flag to determine if it's the primary mailing address.
    It returns an array containing a formatted version of the address with an added "isPrimary" property.
    */
    const getPrimaryMailingAddress = (address: any, isPrimary: boolean) => {
        return [
            {
                "id":address?.addressId,
                "addressLine1": address.primaryAddressLine1 ? address.primaryAddressLine1 : '',
                "addressLine2": address.primaryAddressLine2 ? address.primaryAddressLine2 : '',
                "city": address.primaryCity ? address.primaryCity : '',
                "state": address.primaryState ? address.primaryState : '',
                "zip": address.primaryZip ? address.primaryZip : '',
                "isPrimary": true,
            }
        ];
    }

    /**
     * Constructs an array containing modified beneficial owner objects based on provided data.
     * @param beneficialOwners - The array containing beneficial owner data.
     * @returns An array containing modified beneficial owner objects.
     */
    const getBeneficials = (beneficialOwners: any) => {
        if (!beneficialOwners || !Array.isArray(beneficialOwners)) {
            return []; // Return an empty array if beneficialOwners is falsy or not an array
        }

        return beneficialOwners.map((data: any) => ({
            "id":data['benId'],
            "firstName": data['firstName'] || '',
            "lastName": data['lastName'] || '',
            ...(data?.ssn?.length > 4 && {"ssn": data['ssn']}),
            "dob": data['dob'] && (moment(data['dob']).format('MM/DD/YYYY') || ''),
            "mobilePhone": data['mobilePhone'] && (convertToPhoneRequestFormat(data['mobilePhone']) || ''),
            "homePhone": data['homePhone'] && (convertToPhoneRequestFormat(data['homePhone']) || ''),
            "workPhone": data['workPhone'] && (convertToPhoneRequestFormat(data['workPhone'])  || ''),
            "email": data['email'] || '',
            "mailingAddress": getMailingAddress(data, true),
            "secondaryIdentification" : data['secondaryIdentificationType'] === 'DRIVER_LICENSE' ? {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'stateOfIssuance': data['secondaryIdentificationIssuanceState']
            } : {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'countryOfIssuance': data['secondaryIdentificationType'] === 'ALIEN_REGISTRATION_CARD' ? 'US' : data['secondaryIdentificationIssuance']
            },
            "actAsAuthorizedSignatory": !!(data['authSignatory']?.length),
            "businessDetails": {
                "title": data['jobtitle'] || '',
                "ownershipPercentage": data['ownerShipPercentage'] || ''
            }
        }));
    };
    /**
     * useEffect to update form values and dispatch data for the "page-title-details" component.
     * This effect is triggered when context.data.body changes.
     */
   useEffect(()=>{
    if (context?.data?.body) {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:context?.data?.body?.business? context?.data?.body?.business?.legalName:context?.data?.body?.individual?.firstName, backButton: '/leads' } } });
        setValue('customerType',context?.data?.body?.business?'BUSINESS':'INDIVIDUAL');
        if(context?.data?.body?.business)
        {
             const authorizedSignatoryArray = context?.data?.body?.business?.beneficialOwner?.filter((obj:any) => obj.actAsAuthorizedSignatory === true);
             const nonAuthorizedSignatoryArray = context?.data?.body?.business?.beneficialOwner?.filter((obj:any) => obj.actAsAuthorizedSignatory !== true);
             updateComponentParams(dispatch, props.id, {authorizedSignatoryArray:authorizedSignatoryArray})
             updateComponentParams(dispatch, props.id, {nonAuthorizedSignatoryArray:nonAuthorizedSignatoryArray})
             updateComponentParams(dispatch, props.id, {authItin:authorizedSignatoryArray?.[0]?.taxIdentification?.id || authorizedSignatoryArray?.[0]?.taxIdentification?.idLast4})
            
             setValue('shareVia',context?.data?.body?.business?.email?'EMAIL':'SMS');
            setValue('email',context?.data?.body?.business?.email);
            setValue('businessPhone',context?.data?.body?.business?.phone? convertToPhoneDisplayFormat(context?.data?.body?.business?.phone): '')
            setValue('legalName',context?.data?.body?.business?.legalName);
            setValue('doingBusinessAs',context?.data?.body?.business?.doingBusinessAs);
            setValue('addressLine1',context?.data?.body?.business?.mailingAddress?.[0]?.addressLine1);
            setValue('addressLine2',context?.data?.body?.business?.mailingAddress?.[0]?.addressLine2);
            setValue('city',context?.data?.body?.business?.mailingAddress?.[0]?.city);
            setValue('state',context?.data?.body?.business?.mailingAddress?.[0]?.state?.toUpperCase());
            setValue('zip',context?.data?.body?.business?.mailingAddress?.[0]?.zip);
            setValue('businessCategory',context?.data?.body?.business?.businessCategory?.toUpperCase());
            setValue('ein',context?.data?.body?.business?.ein);
            setValue('dateOfIncorporation',context?.data?.body?.business?.dateOfIncorporation);
            setValue('stateOfIncorporation',context?.data?.body?.business?.stateOfIncorporation?.toUpperCase());
            setValue('website',context?.data?.body?.business?.website);
            setValue('authFirstlName',authorizedSignatoryArray?.[0]?.firstName);
            setValue('authLastlName',authorizedSignatoryArray?.[0]?.lastName);
            setValue('authJobTitle',authorizedSignatoryArray?.[0]?.businessDetails?.title);
            setValue('authOwnershipPercent',authorizedSignatoryArray?.[0]?.businessDetails?.ownershipPercentage);
            setValue('authdob',authorizedSignatoryArray?.[0]?.dob);
            setValue('phone',authorizedSignatoryArray?.[0]?.mobilePhone? convertToPhoneDisplayFormat(authorizedSignatoryArray?.[0]?.mobilePhone):'');
            setValue('email',authorizedSignatoryArray?.[0]?.email);
            setValue('primaryAddressLine1',authorizedSignatoryArray?.[0]?.mailingAddress?.[0]?.addressLine1);
            setValue('primaryAddressLine2',authorizedSignatoryArray?.[0]?.mailingAddress?.[0]?.addressLine2);
            setValue('primaryCity',authorizedSignatoryArray?.[0]?.mailingAddress?.[0]?.city);
            setValue('primaryState',authorizedSignatoryArray?.[0]?.mailingAddress?.[0]?.state?.toUpperCase());
            setValue('primaryZip',authorizedSignatoryArray?.[0]?.mailingAddress?.[0]?.zip);
        }
        else{
            updateComponentParams(dispatch, props.id, {individualItin: context?.data?.body?.individual?.taxIdentification?.id || context?.data?.body?.individual?.taxIdentification?.idLast4})
            setValue('shareVia',context?.data?.body?.individual?.email?'EMAIL':'SMS');
            setValue('email',context?.data?.body?.individual?.email);
            setValue('firstName',context?.data?.body?.individual?.firstName);
            setValue('middleName',context?.data?.body?.individual?.middleName);
            setValue('lastName',context?.data?.body?.individual?.lastName);
            setValue('dob',context?.data?.body?.individual?.dob);
            setValue('ssn',context?.data?.body?.individual?.ssn);
            setValue('mobilePhone',context?.data?.body?.individual?.mobilePhone);
            setValue('addressLine1',context?.data?.body?.individual?.mailingAddress?.[0]?.addressLine1);
            setValue('addressLine2',context?.data?.body?.individual?.mailingAddress?.[0]?.addressLine2);
            setValue('city',context?.data?.body?.individual?.mailingAddress?.[0]?.city);
            setValue('state',context?.data?.body?.individual?.mailingAddress?.[0]?.state?.toUpperCase());
            setValue('zip',context?.data?.body?.individual?.mailingAddress?.[0]?.zip);
            setValue('isPrimary',context?.data?.body?.individual?.mailingAddress?.[0]?.isPrimary);
        }
        
        
       
    }
    // eslint-disable-next-line array-callback-return
   },[context?.data?.body, dispatch, props.id, setValue])
    
   /**
     * Method to handle on submit request
     * @param data : form data
     */
    async function onSubmit(data: any, event: any) {
        const submitButtonName = event?.nativeEvent?.submitter?.name;
        const sendInviteNotification = submitButtonName === 'invite'? true: false;
        setValue('buttonLoader',submitButtonName)
        let _req = data;
        if (data?.shareVia === 'EMAIL') {
            if (!data?.email) {
                setError("email", {
                    type: "required"
                });
                return;
            }
        }
        else if (data?.shareVia === 'SMS') {
            if (!data?.phone) {
                setError("phone", {
                    type: "required"
                });
                return;
            }
        }
        let apiRequest: any = {};
        apiRequest = {
            // "customerType": data.customerType,
            "receiverType":'CUSTOMER',
            
            //"sendInviteNotification":props?.sendInviteNotification,
            ...(sendInviteNotification && {
                "leadId": context?.data?.body?.id,
                "customerType": data.customerType,
                "sendTo": [
                    data['shareVia'] === "SMS"
                        ? { "phone": convertToPhoneRequestFormat(data['phone']) }
                        : { "email": data['email'] }
                ]
            }),
            ...(!sendInviteNotification && {
                "customerId": context?.data?.body?.id,
            }),
            ...reqMapper[data.customerType](_req)
        };

        let status: any = await createInvite(deeplyClean(apiRequest),sendInviteNotification);
        status = processAPIResponse(status)
        if (status.status) {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'leads-list' } });
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: sendInviteNotification?'Invite Send Successfully!':'Draft Send Successfully!'
            });
            history.push('/leads');
        }
        else {
            FxSnackBar.show({
                text: status.message,
            });
        }
    }
    /**
    * `reqMapper` is an object that maps different request types ("BUSINESS" and "INDIVIDUAL") to corresponding request object generators.
    * Each function within this object constructs a specific request object based on the provided request data.
    */
    const reqMapper: any = {
        "BUSINESS": (req: any) => {
            let newReq:any={};
             newReq = {
                   
                   "business": {
                    "beneficialOwner": [],
                    "mailingAddress":  req.addressLine1 && getMailingAddress(req, false),
                    "legalName": req['legalName'],
                    "dateOfIncorporation": req['dateOfIncorporation'] && moment(req['dateOfIncorporation']).format('MM/DD/YYYY'),
                    "stateOfIncorporation": req['stateOfIncorporation'],
                    "ein": req['ein'],
                    "businessCategory": req['businessCategory'],
                    "doingBusinessAs": req['doingBusinessAs'],
                    "phone": req['businessPhone'] && convertToPhoneRequestFormat(req['workPhone']),
                    "website": req['website']
                }
            
            };
            const authSignatoryData={
                "id":props?.authorizedSignatoryArray?.[0]?.id? props?.authorizedSignatoryArray?.[0]?.id:'',
                "firstName": req['authFirstlName'] || '',
                "lastName": req['authLastlName'] || '',
                ...(req?.authSsn?.length > 4 && {"ssn": req['authSsn']}),
                "dob": req['authdob'] && (moment(req['authdob']).format('MM/DD/YYYY') || ''),
                "mobilePhone": req['phone'] && (convertToPhoneRequestFormat(req['phone']) || ''),
                "email": req['email'] || '',
                "mailingAddress": getPrimaryMailingAddress(req, true),
                "actAsAuthorizedSignatory": true,
                "businessDetails": {
                    "title": req['authJobTitle'] || '',
                    "ownershipPercentage": req['authOwnershipPercent'] || ''
                }
                }
                newReq['business']['beneficialOwner'] = getBeneficials(req['beneficialOwner'])
                newReq['business']['beneficialOwner'].push(authSignatoryData);
                newReq?.business?.beneficialOwner.forEach((owner: any) => {
                    if (Array.isArray(owner.mailingAddress)) {
                      owner.mailingAddress = owner.mailingAddress.filter(
                        (address: any) => address.addressLine1 
                      );
                      if (owner.mailingAddress.length === 0) {
                        delete owner.mailingAddress;
                      }
                    }
                  });
            return newReq;
        },
        "INDIVIDUAL": (req: any) => {
            let newReq: any = {
                "individual": {
                    "dob": req['dob'],
                    "firstName": req['firstName'],
                    "lastName": req['lastName'],
                    "middleName": req['middleName'],
                    ...(req?.ssn?.length > 4 && {"ssn": req['ssn']}),
                    "mobilePhone": convertToPhoneRequestFormat(req['phone']),
                    "mailingAddress": getMailingAddress(req, req['setAsPrimary'])
                }
            };
            if(!newReq?.individual?.mailingAddress?.[0]?.addressLine1){
                delete newReq.individual.mailingAddress[0].isPrimary
            }
            return newReq;
        }
    };

    /**
    * Method to call api  for create invite
    * @param payloadData : request payload
    */
    async function createInvite(payloadData: any, sendInviteNotification: boolean) {
        let response='';
        try {
            if(sendInviteNotification){
                response = await httpClient.post(getCustomerUrl('invite/signUp', false), payloadData);
            }
            else{
                response = await httpClient.post(getCustomerUrl('/lead',false), payloadData); 
            }
            return response;
        } catch (error) {
            Logger.error("CreateNewInvite.tsx", "error", error);
            return error;
        }
    }


    /**
    * Method handle the switch onchange event
    * @param value : set as primary status
    */
    const handleSetAsPrimary = (value: any) => {
        setValue('setAsPrimary', value)
    }

    /**
     * Method handles the cancel click event
     */
    const handleCancel = () => {
        history.push('/leads')
    }


    /**
     * Method handles the onchange event
     * @param event : event parameter
     */
    const handleEinChange = (event: any) => {
        try{
            if (event.target.name === 'ein') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{2})/, '$1-');
                val = val.replace(/(\d)-(\d{7}).*/, '$1-$2');
                if (val.length === 10) {
                    clearErrors('ein');
                }
                else {
                    setError("ein", {
                        type: "manual",
                        message: "Invalid EIN",
                    });
                }
                setValue('ein', val)
            }
        }
        catch(e){}
    }
   /*
   * The setOwnerShipPercentageRule object defines rules for ownership percentages..
   */
    const setOwnerShipPercentageRule = {
        required: false,
        min: 0,
        max: 100
    };
    /**
     * Method filters the authorized signatory from beneficial owner array
     */
    const fetchAuthorizedSignatory = (data: any) => {
        try{
            return data?.filter((obj:any) => obj.actAsAuthorizedSignatory === true);
        }
        catch(e){}
    }

     /**
     * Method handles customer Type change
     */
     const handleChangeCustomerType = (event: any) => {
        setValue("legalName",'');
        setValue("firstName",'');
        setValue("lastName",'');
    }
     /**
     * Method handles share via change
     */
     const handleChangeVia = (event: any) => {
        setValue("email",'');
        setValue("phone",'');
    }
   

    return (
      <> 
     { context?.data?.body &&<Grid container xs={12} sm={8} alignItems="flex-start" justifyContent="space-between" className="fx-container fx-form-grid fx-new-invite">
            <Grid id="add-new-invite-first-grid" item xs={12}>
                <Grid id="add-new-invite-sub-grid" container spacing={1} >
                    <FxCard id="add-new-invite-form-card" className="fx-theme-passport">
                        <div className="fx-form-edit-profile">
                        <FormProvider {...methods}>
                            <form id="add-new-invite-form" onSubmit={handleSubmit(onSubmit)}>
                                <FxCardHeader id="add-new-invite-form-card-header"></FxCardHeader>
                                <FxCardBody id="add-new-invite-form-card-body" className="fx-info-card fx-margin-top-reducer fx-margin-top-reducer-media" >
                                <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                    <Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-header'>Invite</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-label'>SHARE VIA *</Typography>
                                            <FxRadioEdit register={{ ...register("shareVia") }} control={control} className={errors.shareVia ? "border-error-input fx-input-edit" : "fx-input-edit"} data={shareViaData} id="edit-lead-form-card-share-via-radio" name="shareVia" defaultValue={'EMAIL'} setValue={setValue} onChange={handleChangeVia} />
                                        </Grid>
                                        {watch('shareVia') === 'EMAIL' && <Grid item xs={12} sm={12}>
                                            <FxTextEdit
                                                register={{ ...register("email") }}
                                                className={
                                                    errors.email
                                                        ? "border-error-input fx-input-edit"
                                                        : "fx-input-edit"
                                                }
                                                control={control}
                                                rules={{ required: watch('customerType') === 'BUSINESS' ? false : true }}
                                                id="edit-lead-form-card"
                                                label={watch('customerType') === 'BUSINESS'?"Authorized Signatory Email *":"Email *"} 
                                                type="email"
                                                name="email"
                                                variant="outlined"
                                                isEditable={true}
                                            />
                                        </Grid>}
                                        {watch('shareVia') === 'SMS' && <Grid item xs={12} sm={12}>
                                            <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(watch('customerType') === 'BUSINESS' ? false : true)} setValue={setValue} disableFlag={true} setError={setError} clearErrors={clearErrors} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="phone" name="phone" label="Phone Number*" defaultValue={context?.data?.body?.business?.beneficialOwner && (fetchAuthorizedSignatory(context?.data?.body?.business?.beneficialOwner)?.[0]?.mobilePhone || context?.data?.body?.individual?.mobilePhone)}/>
                                            <div className={'error-message'}>
                                                {errors.phone && renderPhoneError(errors.phone)}
                                            </div>
                                        </Grid>}
                                        </Grid>
                                    
                                    <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                      
                                        <Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-header'>Lead</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-label'>LEAD TYPE *</Typography>
                                            <FxRadioEdit register={{ ...register("customerType") }} control={control} className={errors.customerType ? "border-error-input fx-input-edit" : "fx-input-edit"} data={customerTypeData} id="edit-lead-invite-form-card-customer-type-radio" name="customerType" defaultValue={'BUSINESS'} setValue={setValue} onChange={handleChangeCustomerType}/>
                                        </Grid>
                                        {watch('customerType') === 'BUSINESS' && <><Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-label'>BUSINESS INFO</Typography>
                                        </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("legalName") }} className={errors.legalName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-lead-invite-form-card-legalName-textbox" label="Legal Business Name *" name="legalName" variant="outlined" setValue={setValue} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("doingBusinessAs") }} className={errors.doingBusinessAs ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-lead-invite-form-card-doingBusinessAs-textbox" label="Doing Business as" name="doingBusinessAs" variant="outlined" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxMaterialSelect register={{ ...register("businessCategory") }} className={errors.businessCategory ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} control={control} id="edit-lead-form-card-businessCategory" name="businessCategory" data={categoryData} label="Company Category"  value={context?.data?.body?.business?.businessCategory?.toUpperCase()} setValue={setValue}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("ein") }} className={errors.ein ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEinValidationOptions(false)} onChange={handleEinChange} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} id="edit-lead-form-card-ein" label="Employer Identification Number (EIN)" name="ein" variant="outlined" placeholder="XX-XXXXXXX" />
                                                <div className={'error-message'}>
                                                    {errors.ein && renderError(errors.ein)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <FxDateEdit register={{ ...register("dateOfIncorporation") }} className={errors.dateOfIncorporation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-lead-form-card-dateOfIncorporation" label="Business Date of Incorporation" name="dateOfIncorporation" setValue={setValue} variant="outlined" disableFuture={true} value={watch('dateOfIncorporation')}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxSelectAutoSearch register={{ ...register("stateOfIncorporation")}} rules={{ required: false }} className={errors.stateOfIncorporation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-lead-form-card-stateOfIncorporation" name="stateOfIncorporation" data={usa_state} label="State of Incorporation" setValue={setValue} value={context?.data?.body?.business?.stateOfIncorporation?.toUpperCase()}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(false)} className={errors.businessPhone ? "border-error-input fx-input-edit fx-phone-edit-kyc fx-phone-edit-select-icon" : "fx-input-edit fx-phone-edit-kyc fx-phone-edit-select-icon"} id="edit-lead-form-card-businessPhone" name="businessPhone" label="Business Phone Number" defaultCountryCode={getKey('countryCode')}  setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                <div className={'error-message'}>
                                                    {errors.businessPhone && renderError(errors.businessPhone)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("website") }} control={control} className={errors.website ? "border-error-input fx-input-edit" : "fx-input-edit"} id="website" name="website" label="Business Website" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                        <Typography className='fx-new-invite-sub-label'>MAILING ADDRESS</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'edit-lead-form-card-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} inputProps={{ maxLength: 30 }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'edit-lead-form-card-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{ maxLength: 30 }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={{ required: false }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="edit-lead-form-card-city-textbox" name="city" label="City" />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                    <FxMaterialSelect register={{ ...register("state") }} rules={{ required: false }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-lead-form-card-add-state-textbox" name="state" data={usa_state} label="State" value={context?.data?.body?.business?.mailingAddress?.[0]?.state?.toUpperCase()} setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(false)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="edit-lead-form-card-zip-textbox" name="zip" label="Zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                       <Typography className='fx-new-invite-sub-label'>AUTHORIZED SIGNATORY DETAILS</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("authFirstlName") }} className={errors.authFirstlName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-new-invite-form-card-authFirstlName-textbox" label="First Name" name="authFirstlName" variant="outlined" setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("authLastlName") }} className={errors.authLastlName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-new-invite-form-card-authLastlName-textbox" label="Last Name" name="authLastlName" variant="outlined" setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("authJobTitle") }} className={errors.authJobTitle ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-new-invite-form-card-authJobTitle-textbox" label="Job Title" name="authJobTitle" variant="outlined" setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <FxTextEdit  register={{ ...register("authOwnershipPercent") }} className={errors.authOwnershipPercent ? "border-error-input fx-input-edit" : "fx-input-edit"}  control={control} rules={setOwnerShipPercentageRule} id="create-new-invite-form-card-authOwnershipPercent-textbox"  type="number" label="Ownership %" suffix="%" name="authOwnershipPercent" variant="outlined" showDecimal={true}  min='0' setValue={setValue} />
                                                        <div className={'error-message'}>
                                                            {errors.authOwnershipPercent && renderOwnershipPercentageError(errors.authOwnershipPercent )}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEditSSN register={{ ...register("authSsn") }} className={errors.authSsn ? "border-error-input fx-input-edit" : "fx-input-edit"} error={errors.authSsn} control={control} rules={{...setSsnValidationOptions(false), ...(props?.authItin && {disabled : true})}} id="create-new-invite-form-card-authSsn-textbox" label="Social Security Number"  placeholder="XXX-XX-XXXX" name="authSsn" variant="outlined" setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                        <div className={'error-message'}>
                                                            {errors.authSsn && renderError(errors.authSsn)}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} >
                                                        <FxDateEdit register={{ ...register("authdob") }} className={errors.authdob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-new-invite-form-card-authdob-textbox" label="Date of Birth" name="authdob" setValue={setValue} variant="outlined" disableFuture={true} value={watch('authdob')} maxDate={ageLimit(18)} />
                                                   </Grid>
                                                   <Grid item xs={12} sm={6}>
                                                        <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(false)} className={errors.phone ? "border-error-input fx-input-edit fx-phone-edit-kyc fx-phone-edit-select-icon" : "fx-input-edit fx-phone-edit-kyc fx-phone-edit-select-icon"} id="create-new-invite-form-card-phone-textbox" name="phone" label="Mobile Number" defaultCountryCode={getKey('countryCode')}  setValue={setValue} setError={setError} clearErrors={clearErrors} isEditable={watch('shareVia') === 'EMAIL'}/>
                                                        <div className={'error-message'}>
                                                            {errors.phone && renderError(errors.phone)}
                                                        </div>
                                                   </Grid>
                                                   <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("email") }}className={errors.email ? "border-error-input fx-input-edit": "fx-input-edit"} control={control}  rules={{ required:false}} id="create-new-invite-email-textbox" label="Email *" type="email" name="email" variant="outlined" isEditable={watch('shareVia') === 'SMS'} setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                       <Typography className='fx-new-invite-sub-label'>PRIMARY ADDRESS</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('primaryAddressLine1') }} control={control} className={errors['primaryAddressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'create-new-invite-primaryAddressLine1-textbox'} label="Address Line 1" name={'primaryAddressLine1'} inputProps={{ maxLength: 30 }} setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('primaryAddressLine2') }} control={control} className={errors['primaryAddressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'create-new-invite-primaryAddressLine2-textbox'} label="Address Line 2" name={'primaryAddressLine1'} inputProps={{ maxLength: 30 }} setValue={setValue}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("primaryCity") }} control={control} rules={{ required: false }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-new-invite-primaryCity-textbox" name="primaryCity" label="City" setValue={setValue}/>
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxMaterialSelect register={{ ...register("primaryState") }} rules={{ required: false }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-new-invite-primaryState-textbox" name="primaryState" data={usa_state} label="State" 
                                                        value={context?.data?.body?.business?.beneficialOwner && fetchAuthorizedSignatory(context?.data?.body?.business?.beneficialOwner)?.[0]?.mailingAddress?.[0]?.state?.toUpperCase()}
                                                         setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("primaryZip") }} control={control} rules={setZipValidationOptions(false)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-new-invite-zip-textbox" name="primaryZip" setValue={setValue} label="Zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={12}><ViewBeneficialOwner id='edit-lead-form-card-view-business-owner' register={register} control={control} errors={errors} setValue={setValue} setError={setError} clearErrors={clearErrors} handleSubmit={handleSubmit} businessCategory={watch('businessCategory')} watch={watch} 
                                                    fields={fields}
                                                    append={append} remove={remove} reset={reset} trigger={trigger} defaultValue={props?.nonAuthorizedSignatoryArray}/></Grid>
                                                 
                                        </>}
                                        {watch('customerType') === 'INDIVIDUAL' && <><Grid item xs={12} sm={12}>
                                            <Typography className='fx-new-invite-sub-label'>INDIVIDUAL INFO</Typography>
                                        </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-lead-invite-form-card-holdername-textbox" label="First Name *" name="firstName" variant="outlined" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("middleName") }} className={errors.middleName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-lead-invite-form-card-holdername-textbox" label="Middle Name" name="middleName" variant="outlined" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("lastName") }} className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="edit-lead-invite-form-card-holdername-textbox" label="Last Name *" name="lastName" variant="outlined" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="edit-lead-form-card-dob" label="Date of Birth" name="dob" setValue={setValue} variant="outlined" disableFuture={true} value={watch('dob')}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEditSSN
                                                    register={{ ...register("ssn") }}
                                                    className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    error={errors?.ssn}
                                                    control={control}
                                                    rules={{...setSsnValidationOptions(false), ...(props?.individualItin && {disabled : true})}}
                                                    id="edit-lead-form-card-form-card-ssn"
                                                    label="Social Security Number"
                                                    placeholder="XXX-XX-XXXX"
                                                    name="ssn"
                                                    variant="outlined"
                                                    setValue={setValue}
                                                    defaultValue={context?.data?.body?.individual?.ssn || context?.data?.body?.individual?.last4ssn}
                                                    setError={setError}
                                                    clearErrors={clearErrors}
                                                />
                                                <div className={'error-message'}>
                                                    {errors.ssn && renderError(errors.ssn)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography>Phone</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(false)} setValue={setValue} disableFlag={true} setError={setError} clearErrors={clearErrors} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="edit-lead-form-card-mobile" name="phone" label="Mobile" isEditable={watch('shareVia') === 'EMAIL'} defaultValue={context?.data?.body?.individual?.mobilePhone} />
                                                <div className={'error-message'}>
                                                    {errors.phone && renderPhoneError(errors.phone)}
                                                </div>
                                            </Grid>
                                            
                                        </>}
                                        

                                        {watch('customerType') === 'INDIVIDUAL' && <Grid item container xs={12} sm={12}>
                                            <fieldset className="fx-add-user-address-container">
                                                <legend>Mailing Address</legend>
                                                <Grid item container xs={12} sm={12} spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'edit-lead-form-card-addressline1-textbox'} label="Address Line 1" name={'addressLine1'} inputProps={{ maxLength: 30 }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false, maxLength: 30 }} id={'edit-lead-form-card-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{ maxLength: 30 }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(false)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="edit-lead-form-card-city-textbox" name="city" label="City" />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                    <FxMaterialSelect register={{ ...register("state") }} rules={{ required: false }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="edit-lead-form-card-state-textbox" name="state" data={usa_state} label="State" value={watch("state")} setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(false)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="edit-lead-form-card-zip-textbox" name="zip" label="Zip" />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>}

                                       
                                        {watch('customerType') === 'INDIVIDUAL' && <Grid item xs={12} sm={12}>
                                            <FxSwitch id='edit-lead-form-card-primary-address-switch' value={watch('setAsPrimary')} onClick={handleSetAsPrimary}></FxSwitch> <FxLabel value="Set as Primary Address"></FxLabel>

                                        </Grid>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    
                                    <Grid item xs={12}>&nbsp; </Grid>
                                </FxCardBody>
                                <FxCardFooter id="edit-lead-form-card-footer" className="fx-footer">
                                <FxButton variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="edit-lead-form-card-cancel-button"
                                            onClick={handleCancel}
                                        >Cancel
                                        </FxButton>
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                       
                                        <span className="fx-padding-right-16" />
                                        <FxButton variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="edit-lead-form-card-cancel-button"
                                            type="submit"
                                            isSubmitting={watch('buttonLoader') === 'draft' && isSubmitting}
                                            name='draft'
                                        > SAVE</FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className={"fx-button fx-button-theme"}
                                            id={"edit-lead-form-card-submit-button"}
                                            type="submit"
                                            isSubmitting={watch('buttonLoader') === 'invite' && isSubmitting}
                                            name='invite'
                                        >Invite
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </form>
                        </FormProvider>
                        </div>
                    </FxCard>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} ></Grid>
                </Grid>
            </Grid>
        </Grid>}
</> 
    )
});