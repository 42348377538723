/**
 * Component:customer-api-tokensList
 * This component handles the  customer-api-tokens list component
 */
import { Divider, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { useDispatch } from 'react-redux';
import { IFilterColumns } from '../../../types/common.interfaces';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import FxCard from '../../Container/FxCard';
import { FxButton } from '../../Action/FxButton';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { DataGrid } from '../../Data/DataGrid';
import { FxGridList } from '../../Data/FxGridList';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { ReactComponent as ViewAPIIcon } from '../../../assets/svg/view-api-doc.svg';
import { truncateChars, updateComponentParams } from '../../Utils/CommonBaseClass';
import { GenerateAPIToken } from './GenerateAPIToken';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { CustomerAPITokenDetails } from './CustomerAPiTokenDetails';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { getKey } from '../../../libs/utils/storageManager';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
type TokenAPIListType = {
    id: string,
    key?: any,
    apiTokenOpen?: any,
    apiDetailsTokenOpen?: any
}
export const CustomerApiTokenList: React.FC<TokenAPIListType> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const isSmallScreen = useIsMobileOrTabletScreen();
    const dispatch = useDispatch();
    const uiFeat = UIFeatureImpl.getInstance();
    const readMeUrl = uiFeat.getReadMeInfo().url;
    const readMeUrlWithVersion = uiFeat.getReadMeInfo().urlWithVersion;

    /**
    * useEffect to reset page while initalising and set page title
    */
    useEffect(() => {
        dispatch({
            type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: "page-title-details", data: { title: `API Keys (Customer ID: ${getKey('customerId')})` } }
        });
    }, [dispatch])


    const src = {
        url: "/token/list",
        method: "POST",
        data: {
            "pageSize": 25,
            "pageNumber": 1,
            "criteria": {
                "filters": [

                ]
            }
        }
    }
    const column: IFilterColumns = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "Name",
                "enable": true,
                "fieldName": "keyName",
                "type": "fri://fieldtype/default",
                "default": true,
                "style":{"width":"30%"}
            },
            {
                "label": "STATUS",
                "enable": true,
                "fieldName": "status",
                "type": "fri://fieldtype/status",
                "default": true,
                "style":{"width":"30%"}
            },
            {
                "label": "Created",
                "enable": true,
                "fieldName": "created",
                "type": "fri://fieldtype/custom",
                "default": true
            },
        ]
    };

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }
        ]
    }
    /*
      This function redirects to the create page .
    */
    const redirectToCreatePage = () => {
        updateComponentParams(dispatch, props?.id, { apiTokenOpen: true })
    }
    /*
      This function redirects to the view page .
    */
    const redirectDetailsPage = (event: any, data: any) => {
        updateComponentParams(dispatch, props?.id, { apiDetailsTokenOpen: true })
        dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-api-token-details' } });
        updateComponentParams(dispatch, 'customer-api-token-details', { tokenId: data?.id })
    }
    /**
       * Handle close event
       */
    const handleClose = () => {
        updateComponentParams(dispatch, props?.id, { apiTokenOpen: false })
    };
    /**
     *Handle close details  
     */
    const handleCloseDetails = () => {
        updateComponentParams(dispatch, props?.id, { apiDetailsTokenOpen: false })
    };
    /*
     Function handles transformation of data to required format
   */
    function transformCustomerAPITokenList(data: any) {
        try {
            let newData: any = [];
            // eslint-disable-next-line array-callback-return
            data?.map((item: any, i: number) => {
                newData[i] = { ...data[i] }
                newData[i].created = <><Grid item container sm={12}><FxDateTimeView  tooltip={true} value={item['createdOn']} format={"MMM DD, yyyy"} ></FxDateTimeView> <Typography className='fx-customer-list-label'> By </Typography> <Typography id={'createdby' + item?.id} title={item?.createdBy}>{truncateChars(item?.createdBy, 30)}</Typography></Grid></>
            });
            return newData;
        }
        catch (e) {
            return false;
        }
    }
    /**
     * Transform data
     * @param data 
     * @returns 
     */
    function transformData(data: any) {
        const newData: any = []

        try {
            const transformedData = newData?.map((item: any, i: number) => {
                const row = {
                    id: item.id,
                    description: (
                        <Grid item container xs={12} className='fx-grid-list-content'>
                            <Grid item container xs={12} alignItems='center'>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Grid>
                                            <Typography variant='h4'>Key Name:{item?.keyName} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs>
                                        <Grid item xs>
                                            <Grid item container justifyContent="flex-end">
                                                <FxStatus id="customer-api-token-list-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Typography variant='h4'></Typography>
                                    </Grid>
                                    <Grid item container xs>
                                        <Grid item xs>
                                            <Grid item container justifyContent="flex-end"><Typography className='fx-customer-info-label'></Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                    divider: (
                        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
                            <Grid item xs>
                                <Divider />
                            </Grid>
                        </Grid>
                    )
                };

                return row;
            });

            return transformedData || [];
        } catch (e) {
            return false;
        }
    }

    /**
     * This method handles the redirection to the API guide in a new tab
     */
    const handleApiGuideRedirection = () => {
        let baseUrl: any = readMeUrl + '?auth_token=' + getKey('readMeToken');
        let apiGuideUrl: any = readMeUrlWithVersion + '/doc-api-user-guide';

        //this code opens up the Authentication Window to bypass the authentication and 
        //then opens up the URL with Version and then closes the Auth Window
        const authenticationWindow = window.open(baseUrl, '_blank')
        setTimeout(()=>{
            window.open(apiGuideUrl, '_blank')
            authenticationWindow?.close();
        }, 3000)        
    }

    return (

        <Grid id="customer-api-token-list-grid" item xs={12}>
            <Grid container xs={12} >
                <FxCard id="customer-api-token-list-card" className="fx-theme-passport">
                    <FxCardHeader id="customer-api-token-list-card-header" className="fx-card-header">
                        <Grid id="customer-api-token-list-card-header-first-grid" item container justifyContent="space-between" xs={12} >
                            <Grid>
                                <div id="ead-list-card-header-div" className="fx-flex-grow" />
                            </Grid>
                            <Grid id="customer-api-token-list-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                                <>
                                    <Grid item>
                                        <FxButton onClick ={handleApiGuideRedirection} className='fx-api-guide-icon'>
                                            <ViewAPIIcon />
                                        </FxButton>
                                    </Grid>
                                    <Grid item>
                                        <FxButton id="customer-api-token-list-add-button" variant="contained" className="fx-button fx-button-theme fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} > GENERATE API TOKEN</FxButton>
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="customer-api-token-list-card-body" className="fx-card-body-no-border-no-shadow">
                        <Grid id="customer-api-token-list-card-body-grid" item xs={12}>
                            {
                                !isSmallScreen ? <DataGrid id="customer-api-token-list-card-data" rowClick={redirectDetailsPage} tableClass="fx-table-passport" dataTransformer={transformCustomerAPITokenList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} /> :
                                    <FxGridList id="customer-api-token-list-card-data" source={src} dataTransformer={transformData} column={gridColumn} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            }
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
            <Modal
                style={{ overflow: 'scroll' }}
                open={props?.apiTokenOpen}
                onClose={handleClose}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small2medium"
            >
                {<GenerateAPIToken id={'generate-api-token-popup'} close={handleClose} />}
            </Modal>
            <Modal
                style={{ overflow: 'scroll' }}
                open={props?.apiDetailsTokenOpen}
                onClose={handleCloseDetails}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small2medium"
            >
                {<CustomerAPITokenDetails id={'customer-api-token-details'} close={handleCloseDetails} />}
            </Modal>


        </Grid>
    )


})