import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { Logger } from "../../../../libs/utils/logger";
import FxCard from "../../../Container/FxCard";
import FxCardHeader from "../../../Container/FxCardHeader";
import { DataGrid } from "../../../Data/DataGrid";
import FxCardBody from "../../../Container/FxCardBody";
import { Filter } from '../../../Data/Filter';
import { IDataGridProps, IFilterColumns, IFilterFields } from "../../../../types/common.interfaces";
import { getKey } from "../../../../libs/utils/storageManager";
import { truncateChars, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { ReactComponent as LeftDownArrow } from "../../../../assets/svg/arrow-left-downbig.svg";
import { ReactComponent as RightUpArrow } from "../../../../assets/svg/arrow-right-topbig.svg";

Logger.debug(
    "FxCardTransactionList.tsx",
    "Funding Rule Card Listing initializing"
);

/**
 * Component: FxCardTransactionList
 * Usage: Listing of the Funding Rule Card
 */
export const FxCardTransactionList: React.FC<any> = React.memo((props) => {
    const dispatch = useDispatch();
    const accountId = getKey("selectedAccount")?.id;

    /**
     * Method contains the API source related info for the cardEvents/list API
     */
     const sourceInfo = () => {
        var filters: any = [];
            const src = {
                url: "cardEvent/list",
                method: "POST",
                data: {
                    pageNumber: 1,
                    pageSize: 25,
                    sortOptions: {
                        sortBy: "lastUpdatedOn",
                        sortOrder: "desc",
                    },
                    criteria: {},
                },
            };
            try {
                filters.push(
                    {
                        "operator": "eq",
                        "key": "status",
                        "values": ['COMPLETED']
                    },
                    {
                        "operator": "in",
                        "key": "fundingRuleId",
                        // parentRuleId is the parent id of funding rule that is inherited and ruleId is the funding rule id created after the deactation of inherited fundingRule
                        "values": props?.parentRuleId ? [props?.parentRuleId, props?.ruleId] : [props?.ruleId]
                    })
                   
                if (accountId) {
                    filters.push(
                    {
                        "key": "account.id",
                        "operator": "eq",
                        "values": [getKey("selectedAccount")?.id]
                    })
                }   
                src.data.criteria = { filters };
            } catch (e) { }
        return src;
    }


    /**
     * useEffect to reset the filter while unmount the page
     */
    useEffect(
        () => () => {
            updateComponentParams(dispatch, "funding-linked-transactions-list-card-filter", { resetFilter: true, });
        },
        [dispatch]
    );

    const filterFields: IFilterFields = {
        "fields": [
            {
                "label": "Batch ID",
                "toLabel": "To",
                "fieldName": "trimmedBatchId",
                "type": "fri://fieldtype/textfield",
                "data": [],
            },
            {
                "label": "Amount",
                "fieldName": "amount",
                "type": "fri://fieldtype/amountrange",
                "data": [],
            },
            {
                "label": "Date",
                "toLabel": "To",
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/daterange",
                "data": [],
                "notAllowFutureDate": true
            }
        ]
    }

    /**
     * Filter Columns
     */
    const column: IFilterColumns = {
        title: "Select Columns to Show",
        columns: [
            {
                enable: true,
                fieldName: "icon",
                type: "fri://fieldtype/custom",
                default: true,
                style: { "width": "4.5rem", "padding-left": "0 !important" },
                dataStyle: { "text-align": "left !important" }
            },
            {
                label: "DATE",
                enable: true,
                fieldName: "createdOn",
                type: "fri://fieldtype/date",
                default: true,
                style: { width: "10rem" },
            },
            {
                label: "BATCH ID",
                enable: true,
                fieldName: "cardBatchId",
                type: "fri://fieldtype/default",
                default: true,
                style: { width: "8rem" },
            },
            {
                label: "TYPE",
                enable: true,
                fieldName: "type",
                type: "fri://fieldtype/default",
                default: true,
                style: { width: "10rem" },
            },
            {
                label: "AUTH CODE",
                enable: true,
                fieldName: "authCode",
                type: "fri://fieldtype/default",
                default: true,
                style: { width: "10rem" },
            },
            {
                label: "STATUS",
                enable: true,
                fieldName: "status",
                type: "fri://fieldtype/status",
                default: true,
                style: { width: "10rem" },
            },
            {
                label: "AMOUNT",
                enable: true,
                fieldName: "amount",
                type: "fri://fieldtype/custom",
                default: true,
                style: { width: "10rem", "text-align": "end" },
            },
            {
                enable: true,
                fieldName: "spacer",
                type: "fri://fieldtype/custom",
                default: true,
                style: { "width": "5.5rem" }
            },
            {
                label: "LAST UPDATED ON",
                enable: true,
                fieldName: "lastUpdatedOn",
                type: "fri://fieldtype/date",
                default: true,
                style: { "width": "8rem" }
            },
        ],
    };

    const datagridProps: IDataGridProps = {
        id: "funding-linked-transactions-data-grid-list",
        column: column,
        pagination: true,
        pageNumber: 1,
        pageSize: 25,
    };

    /**
     * Method handles the transformation of faster funded data
     * @param data
     * @returns : transformed data
     */
    function LinkedtransactionHistoryList(data: any) {
        const newData: any = [];
        data?.forEach((item: any, i: number) => {
            newData[i] = { ...data[i] };
            newData[i].status = item?.status;
            newData[i].statusReason = <><span title={item?.statusReason}>{` ${item.statusReason ? truncateChars(item?.statusReason, 35) : ''}`} &nbsp;</span></>
            newData[i].amount = item?.type === "SALE" ? (
                <FxCurrencyView
                    className={"balance-amount-green"}
                    justifyContent="flex-end"
                    value={item.amount}
                    prefix={"$"}
                    showPlus
                />) : (
                <FxCurrencyView
                    className={"balance-amount-green"}
                    justifyContent="flex-end"
                    value={item.amount}
                    prefix={"$"}
                    showMinus
                />
            )
            newData[i].icon =
                item?.type === "SALE" ? (
                    <div className={"fx-passport-debit-credit-svg"}>
                        <LeftDownArrow />
                    </div>
                ) : (
                    <div className={"fx-passport-debit-credit-svg"}>
                        <RightUpArrow />
                    </div>
                );
        });
        return newData;
    }

    return (
        <Grid container xs={12} className="fx-layout-list">
            <FxCard id="funding-linked-transactions-card" className="fx-theme-passport">
                <FxCardHeader id="split-funding-split-header-second">
                    <Grid container item justifyContent="flex-start" xs>
                        <Typography variant="h3" >Linked Transactions</Typography>
                    </Grid>
                    <Grid id="funding-linked-transactions-list-card-header-filter-grid" xs item display="flex" justifyContent={"flex-end"}>
                        <Filter id="funding-linked-transactions-list-card-filter" field={filterFields} dataGridProps={datagridProps} hiddenParameter={['fundingRuleId', 'status', 'account?.id']}></Filter>
                    </Grid>
                </FxCardHeader>
                <FxCardBody
                    id="funding-linked-transactions-card-body"
                    className="fx-card-body-no-border-no-shadow"
                >
                    <Grid id="funding-linked-transactions-card-body-grid" item xs={12} className="fx-faster-fund-card" >
                        <DataGrid
                            id="funding-linked-transactions-data-grid-list"
                            tableClass="fx-table-passport"
                            dataTransformer={LinkedtransactionHistoryList}
                            source={sourceInfo()}
                            column={column}
                            pagination={true}
                            pageNumber={1}
                            pageSize={25}
                            resultPerPage={25}
                        />
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid>
    )
});
