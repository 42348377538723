/**
 * Component: ViewInviteStatus
 * Usage: For View Invite Status in partner customer page
 */
import React from 'react'; // we need this to make JSX compile
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { Grid, Tooltip, Typography } from '@mui/material';
import { FxButton } from '../../../Action/FxButton';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardHeader from '../../../Container/FxCardHeader';
import { ReactComponent as DoneIcon } from '../../../../assets/svg/progress/progress-done-small.svg';
import { ReactComponent as StartIcon } from '../../../../assets/svg/progress/progress-start-small.svg';
import { ReactComponent as FailedIcon } from '../../../../assets/svg/progress/progress-failed-small.svg';
import { ReactComponent as DoneLine } from '../../../../assets/svg/progress/progress-line-done.svg';
import { ReactComponent as StartLine } from '../../../../assets/svg/progress/progress-line-start.svg';
import { convertToPhoneDisplayFormat, truncateChars, updateComponentData } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';

interface IViewInviteStatus {
  id: string;
  inviteStatus:string;
  data:any;
  resendData:any;
}
interface RouteParams {
  id: string;
}

export const ViewInviteStatus: React.FC<IViewInviteStatus> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const { inviteStatus, data ,resendData} = props
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const status:any = {
    initial: {
      btnText: 'Send Invite',
      indicators: {
        sent: true,
        accepted: false,
        onboarded: false
      }
    },
    sent: {
      btnText: 'Send Reminder',
      indicators: {
        sent: true,
        accepted: false,
        onboarded: false
      }
    },
    expired: {
      btnText: 'Resend Invite',
      indicators: {
        sent: true,
        expired: true,
        onboarded: false
      }
    },
    accepted: {
      btnText: 'Activation Reminder',
      indicators: {
        sent: true,
        accepted: true,
        onboarded: false
      }
    },
    onboarded: {
      btnText: '',
      indicators: {
        sent: true,
        accepted: true,
        onboarded: true
      }
    },
    draft: {
      btnText: 'Send invite',
      indicators: {
        sent: false,
        accepted: false,
        onboarded: false
      }
    },
  }

  const currentStatus = status[inviteStatus];
  /**
   * Method to set the prefilled data
   * @returns 
   */
  function getResendInvite(){
   if( resendData?.business){
    const business=resendData?.business
    const beneficialOwner=business?.beneficialOwner;
     let jsonObject:any= {
      "shareVia": resendData?.email?"EMAIL":"SMS",
      "customerType": "BUSINESS",
      "beneficialOwner": [
         
      ],
      "setAsPrimary": false,
      "legalName": business?.legalName,
      "doingBusinessAs":business?.doingBusinessAs,
      "addressLine1": business?.mailingAddress?business?.mailingAddress[0]?.addressLine1:'',
      "addressLine2": business?.mailingAddress && business?.mailingAddress[0]?.addressLine2?business?.mailingAddress[0]?.addressLine2:'',
      "city": business?.mailingAddress?business?.mailingAddress[0]?.city:'',
      "state":business?.mailingAddress?business?.mailingAddress[0]?.state:'',
      "zip": business?.mailingAddress?business?.mailingAddress[0]?.zip:'',
      "businessCategory": resendData?.businessCategory?resendData?.businessCategory:'',
      "ein":business?.ein?business?.ein:'',
      "dateOfIncorporation": business?.dateOfIncorporation?business?.dateOfIncorporation:'',
      "stateOfIncorporation": business?.stateOfIncorporation?business?.stateOfIncorporation:'',
      "website": business?.website?business?.website:'',
      "businessPhone": business?.mobilePhone?convertToPhoneDisplayFormat(business?.mobilePhone):'',
      "businessPhone-select": "+1"
  
   }
   if(resendData?.email){
    jsonObject['email']= resendData?.email
   }
   else{
    jsonObject['phone']= resendData?.phone
   }
   beneficialOwner?.forEach((item:any,index:any)=>{
    !item.actAsAuthorizedSignatory && jsonObject?.beneficialOwner.push(
      {
        "benId":item?.id,
        "firstName": item?.firstName?item?.firstName:'',
        "lastName":item?.lastName?item?.lastName:'',
        "email": item?.email?item?.email:'',
        "jobtitle":item?.businessDetails?.title?item?.businessDetails?.title:'',
        "ownerShipPercentage":item?.businessDetails?.ownershipPercentage?item?.businessDetails?.ownershipPercentage:'',
        "dob": item?.dob?item?.dob:'',
        "ssn": item?.ssn || item?.last4ssn || '',
        "itin": item?.taxIdentification?.id || item?.taxIdentification?.idLast4 || '',
        "usCitizen": item?.usCitizen?item?.usCitizen:'',
        "mobilePhone": item?.mobilePhone?convertToPhoneDisplayFormat(item?.mobilePhone):'',
        "homePhone":item?.homePhone?convertToPhoneDisplayFormat(item?.homePhone):'',
        "workPhone":item?.workPhone?convertToPhoneDisplayFormat(item?.workPhone):'',
        "secondaryIdentificationType": item?.secondaryIdentification?item?.secondaryIdentification?.type:'',
        "secondaryIdentificationId": item?.secondaryIdentification?item?.secondaryIdentification?.id:'',
        "secondaryIdentificationIssuanceState": item?.secondaryIdentification?.stateOfIssuance? item.secondaryIdentification.stateOfIssuance:'',//
        "secondaryIdentificationIssuance":item?.secondaryIdentification?.countryOfIssuance? item?.secondaryIdentification?.countryOfIssuance:'',//
        "secondaryIdentificationIssuanceAlien": item?.secondaryIdentificationIssuanceAlien?item?.secondaryIdentificationIssuanceAlien:'',
         "addressId":item?.mailingAddress?item?.mailingAddress[0]?.id:'',
        "addressLine1": item?.mailingAddress?item?.mailingAddress[0]?.addressLine1:'',
        "addressLine2": item?.mailingAddress && item?.mailingAddress[0]?.addressLine2?item?.mailingAddress[0]?.addressLine2:'',
        "city": item?.mailingAddress?item?.mailingAddress[0]?.city:'',
        "state":item?.mailingAddress?item?.mailingAddress[0]?.state:'',
        "zip": item?.mailingAddress?item?.mailingAddress[0]?.zip:'',
        "authSignatory":item?.actAsAuthorizedSignatory?item?.actAsAuthorizedSignatory:false
      
    })
  
   })
   return jsonObject;
  }
 else if( resendData?.individual){
    const individual=resendData?.individual
   let jsonObject:any= {
      "shareVia": resendData?.email?"EMAIL":"SMS",
      "customerType": "INDIVIDUAL",
      "setAsPrimary": resendData?.mailingAddress?resendData?.mailingAddress[0]?.isPrimary:false,
      "email": resendData?.email,
      "firstName": individual?.firstName?individual?.firstName:'',
      "lastName":individual?.lastName?individual?.lastName:'',
      "addressLine1": individual?.mailingAddress?individual?.mailingAddress[0]?.addressLine1:'',
      "addressLine2": individual?.mailingAddress && individual?.mailingAddress[0]?.addressLine2?individual?.mailingAddress[0]?.addressLine2:'',
      "city": individual?.mailingAddress?individual?.mailingAddress[0]?.city:'',
      "state":individual?.mailingAddress?individual?.mailingAddress[0]?.state:'',
      "zip": individual?.mailingAddress?individual?.mailingAddress[0]?.zip:'',
      "businessCategory": resendData?.businessCategory?resendData?.businessCategory:'',//
      "ssn":individual?.ssn || individual?.last4ssn || '',
      "dob": individual?.dob?individual?.dob:'',
      "mobile": individual?.mobilePhone?convertToPhoneDisplayFormat(individual?.mobilePhone):'',
      "individual-select": "+1"
  
   }
   return jsonObject;
  }
  else{
      let jsonObject: any = {
        "shareVia": resendData?.email ? "EMAIL" : "SMS",
        "customerType": resendData?.customerType

      }
      if (resendData?.email) {
        jsonObject['email'] = resendData?.email
      }
      else {
        jsonObject['phone'] = resendData?.phone
      }
      return jsonObject;
    }
  }
/**
 * Handle send invite
 */
const handleResendInvite=()=>{
  const prefiled=getResendInvite();
  updateComponentData(dispatch,"lead-invite-prefiled",prefiled)
  history.push(`/lead/edit/${params.id}`)
}
  return (
    <Grid id={`${props.id}-main-grid`} container xs={12}>
      <FxCard id={`${props.id}-card`} className="fx-theme-passport fx-view-invite-status-card">
        <FxCardHeader id={`${props.id}-card-header`}>
          <Typography variant="h3">Invite Status</Typography>
          {inviteStatus==='draft' && <Grid className='fx-lead-draft-status'> <FxStatus value={inviteStatus} fill={true} className="fx-status"  /></Grid>}
          {/* {(currentStatus?.indicators?.expired || inviteStatus==='draft') && <FxButton */}
          {( inviteStatus==='draft') && <FxButton
              className={'fx-button fx-button-theme'}
              id={'invite-status-button'}
              type="submit"
              onClick={handleResendInvite}
          >
            {currentStatus?.btnText} 
          </FxButton>}
         
        </FxCardHeader>
        <FxCardBody id={`${props?.id}-card-body`} className="fx-info-card fx-margin-top-reducer">
          {currentStatus?.indicators?Object.entries(currentStatus?.indicators).map(([key, value], index, array) => {
            const isLastItem = index === array.length - 1;
            const isExpired = key === 'expired';
            return (
              <Grid item container xs={12} key={key} alignItems={'flex-start'} className={'fx-view-invite-status-card-item'}>
                <Grid container item xs={'auto'} marginRight={2} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                  <Grid item>
                    {value ?
                      (isExpired ? <FailedIcon /> : <DoneIcon />)
                      :
                      <StartIcon />
                    }
                  </Grid>
                  {!isLastItem && <Grid item>
                    {value && !isExpired ? <DoneLine /> : <StartLine />}
                  </Grid>}
                </Grid>
                <Grid item>
                  <Grid item xs={12}>
                  <Typography className={'fx-view-invite-status-card-item-info'}>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()} </Typography>
                    {currentStatus?.indicators?.sent && key==='sent'&&<Typography className={'fx-view-invite-status-card-item-info'}>{resendData?.business?.email || resendData?.business?.beneficialOwner?.[0]?.mobilePhone  ?`to ${resendData?.business?.email? truncateChars(resendData?.business?.email,40):resendData?.business?.beneficialOwner?.[0]?.mobilePhone}` :resendData?.individual?.email || resendData?.individual?.mobilePhone ? `to ${resendData?.individual?.email? truncateChars(resendData?.individual?.email,40):resendData?.individual?.mobilePhone}`:''} </Typography>}
                  </Grid>
                  <Grid item xs={12}>
                      {currentStatus?.indicators?.sent && key==='sent' && <Tooltip title={`By ${data?.name} On ${data?.date}`}><Typography className={'fx-view-invite-status-card-item-label'}>{truncateChars( `By ${data?.name} On ${data?.date}`,50)}</Typography></Tooltip>}
                      {currentStatus?.indicators?.accepted && key==='accepted' && <Typography className={'fx-view-invite-status-card-item-label'}>{`On ${data?.date}`}</Typography>}
                  </Grid>
                </Grid>
              </Grid>
            )
          }):''}
        </FxCardBody>
      </FxCard>
    </Grid>
  );
});
